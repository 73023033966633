.searchbox {
    @apply flex opacity-0 invisible;
    transform: translate3d(0, -100%, 0);
    transition-duration: .25s;
    transition-property: transform, opacity;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

    .searchbox-open & {
        @apply opacity-100 visible;
        transform: translate3d(0, 0, 0);
    }

    &-form {
        flex: 1 0 auto;
    }

    &-input {
        padding-right: 50px #{!important};
    }

    &-button {

        &__submit {
            width: 42px;
            height: 42px;
        }
    }
}