.form {

    &-group {
        @apply mb-5;
    }

    &-input, &-select {
        @apply h-9 lg:h-11 #{!important};
    }

    &-input, &-textarea, &-select {
        @apply text-color313131 border-[#707070] w-full px-3 py-2 rounded-none transition ease-in-out placeholder:text-color828282 focus:border-color00873a focus:ring-transparent #{!important};

        &-lg {
            @apply h-12 lg:h-16 px-6 text-lg #{!important};
        }

        &__material {
            @apply px-2 border-r-0 border-l-0 border-t-0 border-b border-b-colorcbcbcb rounded-none #{!important};
        }
    }

    &-check {

        input[type='checkbox'], input[type='radio'] {
            @apply absolute block top-0 left-0 right-0 appearance-none;
            border: none;
            outline: none;

            &:checked {

                ~ label {

                    .dots {
                        @apply bg-color00873a border-color00873a;

                        &::after {
                            @apply opacity-100;
                        }
                    }
                }
            }

            ~ label {
                @apply relative inline-flex items-start mb-0 cursor-pointer overflow-hidden transition ease-in-out;

                .dots {
                    @apply relative bg-transparent mt-1 w-4 h-4 border border-color828282 overflow-hidden;

                    &::after {
                        content: '';
                        @apply absolute border-2 border-t-0 border-r-0 border-solid border-colorffffff opacity-0;
                        height: 4px;
                        width: 9px;
                        top: 4px;
                        left: 3px;
                        border-top: none;
                        border-right: none;
                        transform: rotate(-45deg);
                    }
                }
            }
        }

        &--information {

            input[type='radio'] {

                &:checked {

                    ~ label {
                        @apply bg-colorF7F7F7;
                    }
                }

                ~ label {
                    @apply bg-transparent p-2;
                }
            }

            .information {

                > h6 {
                    @apply mb-2;
                }
            }
        }
    }

    &-file {

        input[type='file'] {
            @apply absolute hidden top-0 left-0 right-0 appearance-none;
            border: none;
            outline: none;
        }
    }

    &-verify {

        @include media-breakpoint-down(md) {
            @apply mt-2 text-right;
        }
    }

    &-prefix {

        &__label {
            @apply absolute top-1/2 left-0 px-3;
            transform: translateY(-50%);

            + .form-input {
                @apply pl-11 #{!important};
            }
        }
    }
}