$primary: #00873A;
$primary-invert: lighten($primary, 100%);

$secondary: #F4D03F;
$secondary-invert: darken($secondary, 100%);

$gray: #EFEFEF;
$gray-invert: darken($gray, 100%);

$dark: #CBCBCB;
$dark-invert: darken($gray, 100%);

$light: #fff;
$light-invert: $primary;

$colors: (
    "primary": ($primary, $primary-invert),
    "secondary": ($secondary, $secondary-invert),
    "gray": ($gray, $gray-invert),
    "dark": ($dark, $dark-invert),
    "light": ($light, $light-invert),
);

.btn {
    @apply inline-flex items-center justify-center h-9 lg:h-11 px-3 border border-transparent font-bold;
    text-align: center;
    outline: 0;
    user-select: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &-sm {
        @apply py-1 text-sm;
        height: unset;
    }

    &-lg {
        @apply h-12 lg:h-16 px-6 text-lg;
    }

    svg {
        @apply h-4 lg:h-5;
    }

    @each $name, $pair in $colors {
        $color: nth($pair, 1);
        $color-invert: nth($pair, 2);
        
        &-#{$name} {
            color: $color-invert;
            background-color: $color !important;
            border-color: lighten($color: $color, $amount: 0%);

            svg {
                fill: $color-invert;
            }

            &:hover {
                color: $color-invert;
                background-color: darken($color: $color, $amount: 7.5%) !important;
                border-color: darken($color: $color, $amount: 7.5%);

                svg {
                    fill: $color-invert;
                }
            }
        }
    }

    @each $name, $pair in $colors {
        $color: nth($pair, 1);
        $color-invert: nth($pair, 2);

        &-outline-#{$name} {
            background-color: transparent;
            border-color: $color;
            color: $color;

            svg {
                fill: $color;
            }

            &:hover {
                color: $color-invert;
                background-color: $color !important;
                border-color: $color;

                svg {
                    fill: $color-invert;
                }
            }
        }
    }
}