.mitra {

    &-title {

        h2 {
            @apply text-color313131;
        }
    }

    &-point {

        &__item {

            h4 {
                @apply font-normal;

                @include media-breakpoint-down(lg) {
                    @apply text-rg;
                }
            }
        }
    }

    &-download {

        &__title {

            img {
                @apply w-12;
            }

            h4 {
                @apply font-normal;
            }
        }
    }

    &-form {

        &__title {

            h2 {
                @apply text-color313131 lg:text-4xl 3xl:text-8xl;
            }
        }
    }
}