.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .splide--sla .splide__track, .splide--coverage .splide__track, .splide--service .splide__track {
    overflow: unset;
  }
}
.splide--testimonial-thumbnail .splide__slide {
  @apply flex items-center;
}
.splide--testimonial-thumbnail .splide__slide:not(.is-active) img {
  @apply grayscale scale-[.8];
}
.splide--testimonial-thumbnail .splide__slide.is-active img {
  @apply scale-150;
}
.splide--home .splide__item {
  @apply block sm:aspect-square md:aspect-square lg:w-full lg:h-[calc(100vh-200px)] overflow-hidden;
}
.splide--home .splide__item img {
  @apply w-full h-full object-cover object-center;
}
.splide__arrows {
  @apply absolute flex items-center justify-center w-full z-10;
}
.splide--home .splide__arrows {
  @apply lg:bottom-12 left-0 right-0;
}
@media (max-width: 991.98px) {
  .splide--home .splide__arrows {
    @apply top-1/2;
    transform: translateY(-50%);
  }
}
.splide--service .splide__arrows {
  @apply top-1/2;
  transform: translateY(-50%);
}
.splide--testimonial-main .splide__arrows {
  @apply hidden;
}
.splide--testimonial-thumbnail .splide__arrows {
  @apply relative;
}
.splide__arrow {
  @apply bg-color00873a w-8 h-8 lg:w-12 lg:h-12 flex items-center justify-center mx-2 rounded-full overflow-hidden;
}
.splide__arrow[disabled] {
  @apply bg-colorF7F7F7 bg-opacity-50 cursor-not-allowed;
}
@media (max-width: 991.98px) {
  .splide--home .splide__arrow {
    @apply absolute;
  }
}
.splide--service .splide__arrow {
  @apply absolute;
}
.splide--service .splide__arrow[disabled] {
  @apply bg-color828282;
}
.splide--testimonial-thumbnail .splide__arrow {
  @apply bg-color828282 mt-4 lg:mt-10;
}
.splide--testimonial-thumbnail .splide__arrow[disabled] {
  @apply bg-colorcbcbcb;
}
.splide--home .splide__arrow--prev {
  @apply -left-1;
}
.splide--service .splide__arrow--prev {
  @apply -left-5 lg:-left-8;
}
.splide--home .splide__arrow--next {
  @apply -right-1;
}
.splide--service .splide__arrow--next {
  @apply -right-5 lg:-right-8;
}

.selectize-control.plugin-drag_drop.multi > .selectize-input.dragging {
  overflow: visible;
}
.selectize-control.plugin-drag_drop.multi > .selectize-input > div.ui-sortable-placeholder {
  visibility: visible !important;
  background: #f2f2f2 !important;
  background: rgba(0, 0, 0, 0.06) !important;
  border: 0 none !important;
  box-shadow: inset 0 0 12px 4px #fff;
}
.selectize-control.plugin-drag_drop .ui-sortable-placeholder::after {
  content: "!";
  visibility: hidden;
}
.selectize-control.plugin-drag_drop .ui-sortable-helper {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.selectize-dropdown.plugin-dropdown_header .selectize-dropdown-header {
  position: relative;
  padding: 10px 8px;
  border-bottom: 1px solid #d0d0d0;
  background: #f8f8f8;
  border-radius: 3px 3px 0 0;
}
.selectize-dropdown.plugin-dropdown_header .selectize-dropdown-header-close {
  position: absolute;
  right: 8px;
  top: 50%;
  color: #303030;
  opacity: 0.4;
  margin-top: -12px;
  line-height: 20px;
  font-size: 20px !important;
  text-decoration: none;
}
.selectize-dropdown.plugin-dropdown_header .selectize-dropdown-header-close:hover {
  color: black;
}

.selectize-dropdown.plugin-optgroup_columns .selectize-dropdown-content {
  display: flex;
}
.selectize-dropdown.plugin-optgroup_columns .optgroup {
  border-right: 1px solid #f2f2f2;
  border-top: 0 none;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
}
.selectize-dropdown.plugin-optgroup_columns .optgroup:last-child {
  border-right: 0 none;
}
.selectize-dropdown.plugin-optgroup_columns .optgroup:before {
  display: none;
}
.selectize-dropdown.plugin-optgroup_columns .optgroup-header {
  border-top: 0 none;
}

.selectize-control.plugin-remove_button .item {
  display: inline-flex;
  align-items: center;
  padding-right: 0 !important;
}
.selectize-control.plugin-remove_button .item .remove {
  color: inherit;
  text-decoration: none;
  vertical-align: middle;
  display: inline-block;
  padding: 2px 6px;
  border-left: 1px solid #d0d0d0;
  border-radius: 0 2px 2px 0;
  box-sizing: border-box;
  margin-left: 6px;
}
.selectize-control.plugin-remove_button .item .remove:hover {
  background: rgba(0, 0, 0, 0.05);
}
.selectize-control.plugin-remove_button .item.active .remove {
  border-left-color: #cacaca;
}
.selectize-control.plugin-remove_button .disabled .item .remove:hover {
  background: none;
}
.selectize-control.plugin-remove_button .disabled .item .remove {
  border-left-color: white;
}

.selectize-control.plugin-clear_button .clear {
  text-decoration: none;
  display: flex;
  position: absolute;
  height: 100%;
  width: 25px;
  top: 0;
  right: calc(8px - 6px);
  color: rgb(0, 0, 0);
  opacity: 0.4;
  font-weight: bold;
  border: none;
  cursor: pointer;
  z-index: 1;
  font-size: 21px;
  justify-content: center;
  align-items: center;
}
.selectize-control.plugin-clear_button .clear:hover {
  opacity: 1;
}
.selectize-control.plugin-clear_button.single .clear {
  right: calc(8px - 6px + 1.5rem);
}

.selectize-dropdown.plugin-auto_position.selectize-position-top {
  border-top: 1px solid #d0d0d0;
  border-bottom: 0 none;
  border-radius: 3px 3px 0 0;
  box-shadow: 0 -6px 12px rgba(0, 0, 0, 0.18);
}

.selectize-control.plugin-auto_position .selectize-input.selectize-position-top.dropdown-active {
  border-radius: 0 0 3px 3px;
  border-top: 0 none;
}
.selectize-control.plugin-auto_position .selectize-input.selectize-position-top.dropdown-active::before {
  top: 0;
  bottom: unset;
}

.selectize-control {
  position: relative;
}

.selectize-dropdown,
.selectize-input,
.selectize-input input {
  color: #303030;
  font-family: inherit;
  font-size: 13px;
  line-height: 18px;
  font-smoothing: inherit;
}

.selectize-input,
.selectize-control.single .selectize-input.input-active {
  background: #fff;
  cursor: text;
  display: inline-block;
}

.selectize-input {
  border: 1px solid #d0d0d0;
  padding: 8px 8px;
  display: inline-block;
  width: 100%;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}
.selectize-control.multi .selectize-input.has-items {
  padding: calc(
      8px - 2px - 0px
    ) 8px calc(
      8px - 2px - 3px -
        0px
    );
}
.selectize-input.full {
  background-color: #fff;
}
.selectize-input.disabled, .selectize-input.disabled * {
  cursor: default !important;
}
.selectize-input.focus {
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15);
}
.selectize-input.dropdown-active {
  border-radius: 3px 3px 0 0;
}
.selectize-input > * {
  vertical-align: baseline;
  display: inline-block;
  zoom: 1;
}
.selectize-control.multi .selectize-input > div {
  cursor: pointer;
  margin: 0 3px 3px 0;
  padding: 2px 6px;
  background: #f2f2f2;
  color: #303030;
  border: 0px solid #d0d0d0;
}
.selectize-control.multi .selectize-input > div.active {
  background: #e8e8e8;
  color: #303030;
  border: 0px solid #cacaca;
}
.selectize-control.multi .selectize-input.disabled > div, .selectize-control.multi .selectize-input.disabled > div.active {
  color: #7d7d7d;
  background: white;
  border: 0px solid white;
}
.selectize-input > input {
  display: inline-block !important;
  padding: 0 !important;
  min-height: 0 !important;
  max-height: none !important;
  max-width: 100% !important;
  margin: 0 !important;
  text-indent: 0 !important;
  border: 0 none !important;
  background: none !important;
  line-height: inherit !important;
  user-select: auto !important;
  box-shadow: none !important;
}
.selectize-input > input::-ms-clear {
  display: none;
}
.selectize-input > input:focus {
  outline: none !important;
}
.selectize-input > input[placeholder] {
  box-sizing: initial;
}
.selectize-input.has-items > input {
  margin: 0 0px !important;
}

.selectize-input::after {
  content: " ";
  display: block;
  clear: left;
}

.selectize-input.dropdown-active::before {
  content: " ";
  display: block;
  position: absolute;
  background: #f0f0f0;
  height: 1px;
  bottom: 0;
  left: 0;
  right: 0;
}

.selectize-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 10;
  border: 1px solid #d0d0d0;
  background: #fff;
  margin: -1px 0 0 0;
  border-top: 0 none;
  box-sizing: border-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 3px 3px;
}
.selectize-dropdown [data-selectable] {
  cursor: pointer;
  overflow: hidden;
}
.selectize-dropdown [data-selectable] .highlight {
  background: rgba(125, 168, 208, 0.2);
  border-radius: 1px;
}
.selectize-dropdown .option,
.selectize-dropdown .optgroup-header,
.selectize-dropdown .no-results,
.selectize-dropdown .create {
  padding: 5px 8px;
}
.selectize-dropdown .option,
.selectize-dropdown [data-disabled],
.selectize-dropdown [data-disabled] [data-selectable].option {
  cursor: inherit;
  opacity: 0.5;
}
.selectize-dropdown [data-selectable].option {
  opacity: 1;
  cursor: pointer;
}
.selectize-dropdown .optgroup:first-child .optgroup-header {
  border-top: 0 none;
}
.selectize-dropdown .optgroup-header {
  color: #303030;
  background: #fff;
  cursor: default;
}
.selectize-dropdown .active {
  background-color: #e8e8e8;
  color: #303030;
}
.selectize-dropdown .active.create {
  color: #495c68;
}
.selectize-dropdown .selected {
  background-color: #e8e8e8;
  color: #303030;
}
.selectize-dropdown .create {
  color: rgba(48, 48, 48, 0.5);
}
.selectize-dropdown .active:not(.selected) {
  background: #f5fafd;
  color: #495c68;
}

.selectize-dropdown-content {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 200px;
  overflow-scrolling: touch;
}

.selectize-dropdown-emptyoptionlabel {
  text-align: center;
}

.selectize-dropdown .spinner {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 5px 8px;
}
.selectize-dropdown .spinner:after {
  content: " ";
  display: block;
  width: 24px;
  height: 24px;
  margin: 3px;
  border-radius: 50%;
  border: 5px solid #d0d0d0;
  border-color: #d0d0d0 transparent #d0d0d0 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.selectize-control.single .selectize-input,
.selectize-control.single .selectize-input input {
  cursor: pointer;
}
.selectize-control.single .selectize-input.input-active, .selectize-control.single .selectize-input.input-active input:not(:read-only) {
  cursor: text;
}
.selectize-control.single .selectize-input:after {
  content: " ";
  display: block;
  position: absolute;
  top: 50%;
  right: 15px;
  margin-top: -3px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #808080 transparent transparent transparent;
}
.selectize-control.single .selectize-input.dropdown-active:after {
  margin-top: -4px;
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #808080 transparent;
}

.selectize-control.rtl {
  text-align: right;
}
.selectize-control.rtl.single .selectize-input:after {
  left: 15px;
  right: auto;
}
.selectize-control.rtl .selectize-input > input {
  margin: 0 4px 0 -2px !important;
}

.selectize-control .selectize-input.disabled {
  opacity: 0.5;
  background-color: #fafafa;
}

.selectize-input,
.selectize-input input,
.status-input .form-custom-selectize .selectize-dropdown {
  line-height: 23px !important;
}

.status-input .form-custom-selectize .selectize-dropdown [data-selectable] {
  padding: 2px 10px !important;
}

.status-input .form-custom-selectize .selectize-input {
  font-size: 16px !important;
  height: 44px !important;
  padding: 8px 12px !important;
}

.status-input .form-custom-selectize .selectize-control.multi .selectize-input > div {
  background: transparent !important;
  border: none !important;
}

.status-input .selectize-control {
  @apply w-full;
}
.selectize-input {
  @apply inline-flex items-center w-full text-color313131 border-[#707070] h-9 lg:h-11 px-3 py-2 text-sm 2xl:text-rg leading-normal shadow-none rounded-none;
  white-space: nowrap;
  overflow: hidden;
}
.input-material .selectize-input {
  @apply px-2 border-r-0 border-l-0 border-t-0 border-b border-b-colorcbcbcb;
}
.status-input .selectize-input {
  @apply h-12 lg:h-16 px-6 text-lg;
}
.status-input .selectize-input.input-active {
  @apply inline-flex !important;
}
.selectize-input.focus {
  @apply border-color00873a shadow-none;
}
.selectize-input.dropdown-active {
  @apply rounded-none;
}
.selectize-input::after {
  @apply hidden !important;
}
.selectize-input input {
  @apply text-color313131 text-sm 2xl:text-rg placeholder:text-color828282;
}

@font-face {
  font-display: swap;
  font-family: "iconfont";
  src: url("../fonts/iconfont.b0a066e5.woff2");
  src: url("../fonts/iconfont.b0a066e5.woff2") format("truetype");
}
.icon {
  font-family: "iconfont";
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}
.icon-logo::before {
  content: "\e74b";
}
.icon-user::before {
  content: "\e734";
}
.icon-globe::before {
  content: "\e736";
}
.icon-search::before {
  content: "\e742";
}
.icon-down::before {
  content: "\e735";
}
.icon-pin::before {
  content: "\e7b4";
}
.icon-phone::before {
  content: "\e7b6";
}

.icon-shangchuantupian:before {
  content: "\e6a2";
}

.icon-rukou_shixiaochaxun:before {
  content: "\e7f3";
}

.icon-LOGO-WhatsAPP:before {
  content: "\e7d4";
}

.icon-mp_denglu-gougou:before {
  content: "\e7a3";
}

.icon-a-icon-jijianshoujian:before {
  content: "\e7ca";
}

.icon-yunluruanjianfuwuxinxi-hengban-yingwen:before {
  content: "\e782";
}

.icon-yunlukejilogo:before {
  content: "\e780";
}

.icon-yunluruanjianfuwuxinxi-hengban-zhongwen:before {
  content: "\e781";
}

.icon-icozhiding:before {
  content: "\e77f";
}

.icon-gouxuan:before {
  content: "\e77d";
}

.icon-weigouxuan:before {
  content: "\e77e";
}

.icon-jiantou:before {
  content: "\e77b";
}

.icon-jiantoushang:before {
  content: "\e77c";
}

.icon-daohang_fanhui:before {
  content: "\e773";
}

.icon-daohang_guanbi:before {
  content: "\e774";
}

.icon-daohang_caidan:before {
  content: "\e775";
}

.icon-daohang_denglu:before {
  content: "\e776";
}

.icon-daohang_zhuye:before {
  content: "\e77a";
}

.icon-gerenzhongxin_wodedingdan:before {
  content: "\e777";
}

.icon-gerenzhongxin_tongxunlu:before {
  content: "\e778";
}

.icon-gerenzhongxin_gerenxinxi:before {
  content: "\e779";
}

.icon-icoJFSyunyinghexinxitong:before {
  content: "\e771";
}

.icon-icokefugongdanguanlixitong:before {
  content: "\e76b";
}

.icon-icoguanlibaobiaoleixitong:before {
  content: "\e76c";
}

.icon-icohujiaozhongxinxitong:before {
  content: "\e76d";
}

.icon-icoshouchizhongduanguanlixitong:before {
  content: "\e76e";
}

.icon-icokehuguanxiguanlixitong:before {
  content: "\e76f";
}

.icon-icoyunliguanlixitong:before {
  content: "\e770";
}

.icon-icofenjianzhichixitong:before {
  content: "\e772";
}

.icon-icozaixiankefu:before {
  content: "\e7c8";
}

.icon-biaozhunkuaiyun_bai:before {
  content: "\e7c7";
}

.icon-xiaohuotong_bai:before {
  content: "\e7c6";
}

.icon-shixiaofuwu_bai:before {
  content: "\e7c5";
}

.icon-icon_taiguo:before {
  content: "\e763";
}

.icon-icon_xinjiapo:before {
  content: "\e764";
}

.icon-icon_yuenan:before {
  content: "\e765";
}

.icon-icon_malai:before {
  content: "\e766";
}

.icon-icon_zhongguo:before {
  content: "\e767";
}

.icon-icon_feilvbin:before {
  content: "\e768";
}

.icon-icon_yinni:before {
  content: "\e769";
}

.icon-icon_jianpuzhai:before {
  content: "\e76a";
}

.icon-icon-fenjian:before {
  content: "\e7c0";
}

.icon-icon-zhuanyun:before {
  content: "\e7c1";
}

.icon-icon-cheliang:before {
  content: "\e7c2";
}

.icon-icon-yuangong:before {
  content: "\e7c3";
}

.icon-icon-wangdian:before {
  content: "\e7c4";
}

.icon-anquanbaozhuang:before {
  content: "\e7b8";
}

.icon-cangpeiyiti:before {
  content: "\e7b9";
}

.icon-qiandanfanhuan:before {
  content: "\e7ba";
}

.icon-huowubaojia:before {
  content: "\e7bb";
}

.icon-dingshipaisong:before {
  content: "\e7bc";
}

.icon-daishouhuokuan:before {
  content: "\e7bd";
}

.icon-rucangfuwu:before {
  content: "\e7be";
}

.icon-weituoshouhuo:before {
  content: "\e7bf";
}

.icon-icon-xinxiqipao:before {
  content: "\e7b7";
}

.icon-icon-dizhi:before {
  content: "\e7b4";
}

.icon-icon-shijian:before {
  content: "\e7b5";
}

.icon-icon-dianhua:before {
  content: "\e7b6";
}

.icon-icon-shengshiqu:before {
  content: "\e7b3";
}

.icon-icon-cuowutishi:before {
  content: "\e7b1";
}

.icon-icon-chenggongtishi:before {
  content: "\e7b2";
}

.icon-icon-wancheng:before {
  content: "\e7b0";
}

.icon-icon-daoda:before {
  content: "\e7af";
}

.icon-bianji:before {
  content: "\e762";
}

.icon-xinzeng:before {
  content: "\e761";
}

.icon-danchuangfanhui:before {
  content: "\e75f";
}

.icon-danchuangguanbi:before {
  content: "\e760";
}

.icon-dengluzhuce_mimayincang:before {
  content: "\e75d";
}

.icon-dengluzhuce_mimakeshi:before {
  content: "\e75e";
}

.icon-xinxi_3ji:before {
  content: "\e754";
}

.icon-xinxi_2ji:before {
  content: "\e755";
}

.icon-xinxi_4ji:before {
  content: "\e756";
}

.icon-xinxi_5ji:before {
  content: "\e757";
}

.icon-xinxi_6ji:before {
  content: "\e758";
}

.icon-xinxi_7ji:before {
  content: "\e759";
}

.icon-xinxi_1ji:before {
  content: "\e75a";
}

.icon-xinxi_9ji:before {
  content: "\e75b";
}

.icon-xinxi_8ji:before {
  content: "\e75c";
}

.icon-xinxi_baozhuangcailiaobudang:before {
  content: "\e74d";
}

.icon-xinxi_qiguan:before {
  content: "\e74e";
}

.icon-xinxi_falvjinzhi:before {
  content: "\e74f";
}

.icon-xinxi_dupin:before {
  content: "\e750";
}

.icon-xinxi_xuanchuancailiao:before {
  content: "\e751";
}

.icon-xinxi_yiranwu:before {
  content: "\e752";
}

.icon-xinxi_qiangzhi:before {
  content: "\e753";
}

.icon-icon-houtui:before {
  content: "\e79c";
}

.icon-icon-tongxunlu:before {
  content: "\e79e";
}

.icon-icon-qingkong:before {
  content: "\e79f";
}

.icon-icon-weixuan:before {
  content: "\e7a0";
}

.icon-icon-qianjin:before {
  content: "\e7a1";
}

.icon-icon-xuanzhong:before {
  content: "\e7a2";
}

.icon-a-shixiaofuwu1x:before {
  content: "\e744";
}

.icon-a-xiaohuotong1x:before {
  content: "\e74a";
}

.icon-a-biaozhunkuaiyun1x:before {
  content: "\e74c";
}

.icon-a-banner_JTCargo:before {
  content: "\e743";
}

.icon-logo:before {
  content: "\e74b";
}

.icon-shouye_fuwubaozhang_bianjiexiadan:before {
  content: "\e73c";
}

.icon-shouye_fuwubaozhang_biaozhunguanli:before {
  content: "\e73d";
}

.icon-shouyedibu_youxiang:before {
  content: "\e73e";
}

.icon-shouye_fuwubaozhang_quanchenggenzong:before {
  content: "\e73f";
}

.icon-shouyedibu_dizhi:before {
  content: "\e740";
}

.icon-shouye_fuwubaozhang_lipeibaozhang:before {
  content: "\e741";
}

.icon-sousuo_chaxunyundan:before {
  content: "\e742";
}

.icon-shouyedibu_rexian:before {
  content: "\e745";
}

.icon-zengzhifuwu_gou:before {
  content: "\e746";
}

.icon-shouye_fuwubaozhang_zhuanyeyunzuo:before {
  content: "\e747";
}

.icon-shouye_fuwubaozhang_jisuxiangying:before {
  content: "\e748";
}

.icon-zengzhifuwu:before {
  content: "\e749";
}

.icon-fuwubaozhang_shangyiye:before {
  content: "\e738";
}

.icon-fuwubaozhang_xiayiye:before {
  content: "\e73a";
}

.icon-gengduo:before {
  content: "\e737";
}

.icon-rukou_zaixianjijian:before {
  content: "\e739";
}

.icon-rukou_wangdianchaxun:before {
  content: "\e73b";
}

.icon-daohang_icoyuyan:before {
  content: "\e736";
}

.icon-daohang_jiantoushouqi:before {
  content: "\e733";
}

.icon-daohang_icodengluzhuce:before {
  content: "\e734";
}

.icon-daohang_jiantouxiala:before {
  content: "\e735";
}

.btn {
  @apply inline-flex items-center justify-center h-9 lg:h-11 px-3 border border-transparent font-bold;
  text-align: center;
  outline: 0;
  user-select: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-sm {
  @apply py-1 text-sm;
  height: unset;
}
.btn-lg {
  @apply h-12 lg:h-16 px-6 text-lg;
}
.btn svg {
  @apply h-4 lg:h-5;
}
.btn-primary {
  color: white;
  background-color: #00873A !important;
  border-color: #00873a;
}
.btn-primary svg {
  fill: white;
}
.btn-primary:hover {
  color: white;
  background-color: #00612a !important;
  border-color: #00612a;
}
.btn-primary:hover svg {
  fill: white;
}
.btn-secondary {
  color: black;
  background-color: #F4D03F !important;
  border-color: #f4d03f;
}
.btn-secondary svg {
  fill: black;
}
.btn-secondary:hover {
  color: black;
  background-color: #f2c71b !important;
  border-color: #f2c71b;
}
.btn-secondary:hover svg {
  fill: black;
}
.btn-gray {
  color: black;
  background-color: #EFEFEF !important;
  border-color: #efefef;
}
.btn-gray svg {
  fill: black;
}
.btn-gray:hover {
  color: black;
  background-color: gainsboro !important;
  border-color: gainsboro;
}
.btn-gray:hover svg {
  fill: black;
}
.btn-dark {
  color: black;
  background-color: #CBCBCB !important;
  border-color: #cbcbcb;
}
.btn-dark svg {
  fill: black;
}
.btn-dark:hover {
  color: black;
  background-color: #b8b8b8 !important;
  border-color: #b8b8b8;
}
.btn-dark:hover svg {
  fill: black;
}
.btn-light {
  color: #00873A;
  background-color: #fff !important;
  border-color: white;
}
.btn-light svg {
  fill: #00873A;
}
.btn-light:hover {
  color: #00873A;
  background-color: #ececec !important;
  border-color: #ececec;
}
.btn-light:hover svg {
  fill: #00873A;
}
.btn-outline-primary {
  background-color: transparent;
  border-color: #00873A;
  color: #00873A;
}
.btn-outline-primary svg {
  fill: #00873A;
}
.btn-outline-primary:hover {
  color: white;
  background-color: #00873A !important;
  border-color: #00873A;
}
.btn-outline-primary:hover svg {
  fill: white;
}
.btn-outline-secondary {
  background-color: transparent;
  border-color: #F4D03F;
  color: #F4D03F;
}
.btn-outline-secondary svg {
  fill: #F4D03F;
}
.btn-outline-secondary:hover {
  color: black;
  background-color: #F4D03F !important;
  border-color: #F4D03F;
}
.btn-outline-secondary:hover svg {
  fill: black;
}
.btn-outline-gray {
  background-color: transparent;
  border-color: #EFEFEF;
  color: #EFEFEF;
}
.btn-outline-gray svg {
  fill: #EFEFEF;
}
.btn-outline-gray:hover {
  color: black;
  background-color: #EFEFEF !important;
  border-color: #EFEFEF;
}
.btn-outline-gray:hover svg {
  fill: black;
}
.btn-outline-dark {
  background-color: transparent;
  border-color: #CBCBCB;
  color: #CBCBCB;
}
.btn-outline-dark svg {
  fill: #CBCBCB;
}
.btn-outline-dark:hover {
  color: black;
  background-color: #CBCBCB !important;
  border-color: #CBCBCB;
}
.btn-outline-dark:hover svg {
  fill: black;
}
.btn-outline-light {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
}
.btn-outline-light svg {
  fill: #fff;
}
.btn-outline-light:hover {
  color: #00873A;
  background-color: #fff !important;
  border-color: #fff;
}
.btn-outline-light:hover svg {
  fill: #00873A;
}

.card--services {
  box-shadow: 0px 20px 6px rgba(0, 0, 0, 0.1607843137);
}
.card--services::before {
  content: "";
  @apply absolute bg-color313131 bottom-0 right-0 left-0 h-4 transition ease-in-out;
}
.card--services:hover::before {
  @apply bg-color00873a;
}
.card--services:hover h3, .card--services:hover .actions {
  @apply text-color00873a;
}
.card--services:hover svg {
  @apply fill-color00873a;
}
.card--services-related {
  @apply block !important;
}
.card--maps {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
.card--point-about {
  @apply flex flex-col items-center justify-center lg:h-[275px];
}
.card--services-index .card-header {
  @apply lg:w-[175px] lg:h-full;
}
@media (max-width: 991.98px) {
  .card--services-index .card-header .images {
    @apply bg-top h-[175px];
  }
}
.card--services-related .card-header {
  @apply lg:flex-col lg:items-start;
}
.card--services-related .card-header h3 {
  @apply leading-none text-rg lg:order-2 lg:text-xl;
}
.card--services-related .card-header .images {
  @apply bg-top lg:order-1 lg:ml-0 h-[200px];
}
.card--testimonial .card-header img {
  @apply h-13;
}
.card--point-services .card-header {
  @apply w-8 lg:w-20;
}
.card--point-services .card-header img {
  @apply w-8 lg:w-20;
}
.card--point-about .card-header img {
  @apply mx-auto h-14 lg:h-21;
}
.card--news .card-header {
  @apply w-[100px] h-[100px] lg:w-[300px] lg:h-[175px];
}
.card--news .card-header img {
  @apply object-cover object-center w-full h-full;
}
.card--services .card-content h3 {
  @apply mb-2 text-color00873a leading-none text-rg lg:text-3xl 2xl:text-4xl;
}
.card--services .card-content .entry p {
  @apply line-clamp-4;
}
@media (max-width: 991.98px) {
  .card--services-index .card-content {
    @apply pb-0;
  }
}
@media (min-width: 992px) {
  .card--services-index .card-content {
    @apply pr-0;
  }
}
.card--services-related .card-content {
  @apply lg:p-6 pb-0;
}
.card--services-related .card-content .entry p {
  @apply line-clamp-3 h-[63px] lg:line-clamp-5 lg:h-[98px];
}
.card--services-related .card-content .actions {
  @apply w-full;
}
.card--services-related .card-content .actions span {
  @apply ml-0;
}
.card--testimonial .card-content .caption h6 {
  @apply font-normal lg:text-lg;
}
.card--point-services .card-content h3 {
  @apply font-normal text-rg lg:text-lg;
}
.card--point-about .card-content h3 {
  @apply lg:text-6xl;
}
.card--news .card-content > h2 {
  @apply lg:my-3 text-rg lg:text-lg line-clamp-2 lg:h-[50px];
}
@media (max-width: 991.98px) {
  .card--news .card-content > h2 {
    @apply mt-3;
  }
}
.card--news .card-content .info {
  @apply text-sm;
}
.card--news .card-content .info-tag {
  @apply p-[2px] lg:p-4 font-bold text-color00873a;
}
.card--news .card-content .info-date {
  @apply p-[2px] lg:p-4 text-colorcbcbcb;
}
.card--news .card-content .excerpt p {
  @apply line-clamp-2;
  height: 48px;
}
.card--member .card-content .title {
  @apply relative lg:w-[130px] text-color828282;
}
.card--member .card-content .title::before {
  content: ":";
  @apply absolute right-0 top-0;
}
.card--member .card-content .content {
  @apply flex-1 text-color828282 lg:pl-2;
}
.card--member-informasi .card-content .title {
  @apply lg:w-auto;
}
.card--member-informasi .card-content .title::before {
  content: "";
}
.card--member-informasi .card-content .content {
  @apply pr-6 lg:text-right;
}
.card--maps .card-content .images img {
  @apply w-full h-full object-cover object-center;
}
.card--maps .card-content .content-address p {
  @apply line-clamp-2;
}
.card--maps .card-content .content h6 {
  @apply mb-1;
}
.card--quality .card-content p {
  @apply mt-4 lg:text-lg;
}

.media--info .media-content h6 {
  @apply mb-1 font-normal;
}
.media--info .media-content p {
  @apply text-[#666];
}

.form-group {
  @apply mb-5;
}
.form-input, .form-select {
  @apply h-9 lg:h-11 !important;
}
.form-input, .form-textarea, .form-select {
  @apply text-color313131 border-[#707070] w-full px-3 py-2 rounded-none transition ease-in-out placeholder:text-color828282 focus:border-color00873a focus:ring-transparent !important;
}
.form-input-lg, .form-textarea-lg, .form-select-lg {
  @apply h-12 lg:h-16 px-6 text-lg !important;
}
.form-input__material, .form-textarea__material, .form-select__material {
  @apply px-2 border-r-0 border-l-0 border-t-0 border-b border-b-colorcbcbcb rounded-none !important;
}
.form-check input[type=checkbox], .form-check input[type=radio] {
  @apply absolute block top-0 left-0 right-0 appearance-none;
  border: none;
  outline: none;
}
.form-check input[type=checkbox]:checked ~ label .dots, .form-check input[type=radio]:checked ~ label .dots {
  @apply bg-color00873a border-color00873a;
}
.form-check input[type=checkbox]:checked ~ label .dots::after, .form-check input[type=radio]:checked ~ label .dots::after {
  @apply opacity-100;
}
.form-check input[type=checkbox] ~ label, .form-check input[type=radio] ~ label {
  @apply relative inline-flex items-start mb-0 cursor-pointer overflow-hidden transition ease-in-out;
}
.form-check input[type=checkbox] ~ label .dots, .form-check input[type=radio] ~ label .dots {
  @apply relative bg-transparent mt-1 w-4 h-4 border border-color828282 overflow-hidden;
}
.form-check input[type=checkbox] ~ label .dots::after, .form-check input[type=radio] ~ label .dots::after {
  content: "";
  @apply absolute border-2 border-t-0 border-r-0 border-solid border-colorffffff opacity-0;
  height: 4px;
  width: 9px;
  top: 4px;
  left: 3px;
  border-top: none;
  border-right: none;
  transform: rotate(-45deg);
}
.form-check--information input[type=radio]:checked ~ label {
  @apply bg-colorF7F7F7;
}
.form-check--information input[type=radio] ~ label {
  @apply bg-transparent p-2;
}
.form-check--information .information > h6 {
  @apply mb-2;
}
.form-file input[type=file] {
  @apply absolute hidden top-0 left-0 right-0 appearance-none;
  border: none;
  outline: none;
}
@media (max-width: 767.98px) {
  .form-verify {
    @apply mt-2 text-right;
  }
}
.form-prefix__label {
  @apply absolute top-1/2 left-0 px-3;
  transform: translateY(-50%);
}
.form-prefix__label + .form-input {
  @apply pl-11 !important;
}

@keyframes checkmark {
  0% {
    background-position-y: 5px;
  }
  50% {
    background-position-y: -2px;
  }
  100% {
    background-position-y: 0;
  }
}
.status-input__radio .form-check {
  @apply relative inline-flex items-center cursor-pointer;
}
.status-input__radio .form-check input[type=radio] {
  @apply relative w-4 h-4 rounded-full appearance-none outline-none cursor-pointer;
  border: 1px solid #828282;
  transition: 0.25s;
}
.status-input__radio .form-check input[type=radio]:checked {
  @apply bg-color00873a border-color00873a;
  animation: checkmark 0.2s ease-in;
}
.status-input__radio .form-check label {
  @apply inline-block flex-1 pl-2 mb-0 cursor-pointer;
}

.invalid {
  @apply border-colorFD0000 focus:border-colorFD0000 !important;
}
.invalid-feedback {
  @apply text-colorFD0000;
}

.navbar {
  @apply bg-colorffffff h-14 lg:h-19;
  transition: ease-in-out 0.25s, height 0.25s, opacity 0.25s;
}
.navbar--page-index {
  @apply lg:bg-color000000 lg:bg-opacity-25 text-colorffffff;
}
.navbar--page-index .navbar-menu {
  @apply hidden lg:flex;
}
.navbar--page-index .navbar-menu__list a, .navbar--page-index .navbar-menu__list .dropdown-toggle {
  @apply hover:text-colorffd447;
}
.navbar--fixed {
  @apply bg-colorffffff shadow-rg lg:h-14;
}
.navbar--fixed .navbar-menu {
  @apply hidden lg:flex;
}
.navbar--fixed .navbar-menu__list a, .navbar--fixed .navbar-menu__list .dropdown-toggle {
  @apply hover:text-color00873a;
}
.navbar-logo {
  @apply text-color00873a;
}
.navbar--page-index .navbar-logo {
  @apply lg:text-colorffffff;
}
.navbar--fixed .navbar-logo {
  @apply text-color00873a;
}
.navbar-hamburger {
  @apply flex lg:hidden;
}
.navbar-hamburger__btn > span {
  @apply bg-color313131 block w-6 transition ease-in-out rotate-0;
  height: 1.5px;
}
.navbar-hamburger__btn > span:nth-child(2) {
  @apply w-4;
}
.navbar-hamburger__btn > span + span {
  @apply mt-2;
}
.navbar-hamburger__btn.show > span:nth-child(1) {
  margin-top: 0;
  transform: rotate(135deg);
}
.navbar-hamburger__btn.show > span:nth-child(2) {
  opacity: 0;
}
.navbar-hamburger__btn.show > span:nth-child(3) {
  @apply w-6;
  margin-top: -10.5px;
  transform: rotate(-135deg);
}
.navbar-menu {
  @apply hidden lg:flex;
}
.navbar--fixed .navbar-menu {
  @apply text-color313131;
}
.navbar-menu__list a, .navbar-menu__list .dropdown-toggle {
  @apply hover:text-color00873a;
}
@media (min-width: 992px) {
  .navbar-search {
    @apply ml-8;
  }
}
.navbar-search__btn .close {
  @apply hidden;
}
.searchbox-open .navbar-search__btn .close {
  @apply block;
}
.searchbox-open .navbar-search__btn .search {
  @apply hidden;
}

.dropdown {
  @apply relative;
}
.dropdown.active .dropdown-toggle .icon-down {
  @apply rotate-180;
  transition: transform 0.25s;
}
.dropdown.active .dropdown-item {
  @apply block;
}
.dropdown-toggle {
  @apply flex items-center cursor-pointer;
}
.dropdown-item {
  @apply bg-colorffffff absolute hidden top-14 min-w-[175px] h-auto rounded-sm overflow-hidden;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
}
.dropdown-item--end {
  @apply right-6;
}
.dropdown-item a {
  @apply text-color313131 hover:text-color00873a;
}
.navbar--fixed .dropdown-item {
  @apply top-[38px];
}

.jumbotron {
  @apply h-[calc(200px+10vw)];
}
.jumbotron--about, .jumbotron--order, .jumbotron--services {
  @apply text-colorffffff;
}
.jumbotron--services .jumbotron-images::before {
  content: "";
  @apply absolute top-0 right-0 left-0 h-full;
  background: linear-gradient(90deg, rgb(56, 56, 56) -15%, rgb(255, 255, 255) 100%);
  mix-blend-mode: multiply;
}
.jumbotron-images {
  @apply w-full;
}
.jumbotron-images img {
  @apply w-full h-full object-cover object-top;
}
.jumbotron-body h2 {
  @apply leading-none lg:text-7xl;
}
.jumbotron-body p {
  @apply mt-4 lg:text-lg;
}
.jumbotron--mitra-footer .jumbotron-body {
  @apply sm:w-full w-1/3 lg:pr-21;
}
.jumbotron--mitra-footer .jumbotron-body p {
  @apply lg:text-xl;
}
@media (max-width: 991.98px) {
  .jumbotron-tag h4 {
    @apply text-rg;
  }
}

.accordion-item:not(:first-of-type) {
  @apply border-t-0;
}
.accordion-toggle[aria-expanded=true] {
  background-color: inherit;
  transition-duration: 0.75s;
  transition-property: background-color, border-color;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.accordion-toggle[aria-expanded=true] .icon svg {
  @apply rotate-180;
}
.accordion-toggle[aria-expanded=false] {
  transition-duration: 0.75s;
  transition-property: background-color, border-color;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.accordion--recruitment .accordion-toggle h6 {
  @apply font-bold;
}
@media (max-width: 767.98px) {
  .accordion-toggle h6 {
    @apply text-sm;
  }
}
.accordion-toggle .icon svg {
  @apply h-3 fill-color000000;
  transition-duration: 0.5s;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.accordion-content {
  transition-duration: 0.5s;
  transition-property: transform, opacity;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.accordion-content[aria-hidden=true] {
  height: 0;
  opacity: 0;
  transform: translate3d(0, -0.5rem, 0);
  visibility: hidden;
}
.accordion-content[aria-hidden=false] {
  height: auto;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  visibility: visible;
}

.tab--member .tab-link[aria-selected=true], .tab--single .tab-link[aria-selected=true] {
  @apply text-color00873a border-b-color00873a;
}

.popup {
  @apply bg-color000000 bg-opacity-50 fixed top-0 left-0 w-full h-full overflow-x-hidden overflow-y-auto;
  z-index: 1056;
}
.popup-dialog {
  @apply relative flex items-center mx-16 lg:mx-auto lg:max-w-[300px];
  min-height: calc(100% - 3.5rem);
}
.popup-content {
  @apply bg-colorffffff relative flex flex-col w-full;
}

.modal-outlet .modal-dialog {
  @apply lg:max-w-[750px];
}
.modal-body .status-outlet__list {
  @apply lg:pr-4 h-[540px] lg:h-[400px] overflow-y-scroll;
}
.modal-body .status-outlet__list::-webkit-scrollbar {
  @apply w-2 h-3 rounded-full;
}
.modal-body .status-outlet__list::-webkit-scrollbar-thumb {
  @apply bg-color828282 rounded-full;
}
.modal-body .status-outlet__list-item {
  @apply mb-4 pb-4 lg:mb-6 lg:pb-6 border-b border-b-colorcbcbcb;
}
.modal-body .status-outlet__list .title {
  @apply relative;
}
.modal-body .status-outlet__list .title::after {
  content: ":";
  @apply absolute top-0 right-0;
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 0.25s;
}

.searchbox {
  @apply flex opacity-0 invisible;
  transform: translate3d(0, -100%, 0);
  transition-duration: 0.25s;
  transition-property: transform, opacity;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.searchbox-open .searchbox {
  @apply opacity-100 visible;
  transform: translate3d(0, 0, 0);
}
.searchbox-form {
  flex: 1 0 auto;
}
.searchbox-input {
  padding-right: 50px !important;
}
.searchbox-button__submit {
  width: 42px;
  height: 42px;
}

.hamburger {
  transform: translate3d(0, -100%, 0);
}
.hamburger.show {
  @apply visible opacity-100;
  transform: translate3d(0, 0, 0);
}

.pagination-item {
  @apply bg-transparent text-color828282 inline-flex items-center justify-center w-8 h-8 overflow-hidden;
}
.pagination-item.current {
  @apply bg-color00873a text-colorffffff;
}

.tree__open > a > span {
  transform: rotate(-90deg);
}

.treeview > a {
  @apply flex items-center !important;
}
.treeview > a span {
  @apply ml-auto;
}
.treeview__menu {
  @apply hidden;
}
.treeview__menu > li {
  @apply relative;
}

@media (max-width: 991.98px) {
  .section--service {
    background-size: 150% !important;
    background-position: right top !important;
  }
}
@media (max-width: 991.98px) {
  .section--testimonial {
    background-image: url("../images/BG-Testimoni-Kami@2x-mobile.png") !important;
    background-size: 110% !important;
    background-position: center top !important;
  }
}
.section-title h2 {
  @apply text-color000000 text-7xl lg:text-[62px];
}
@media (max-width: 991.98px) {
  .section-title {
    @apply px-8 !important;
  }
}

.post-title h1 {
  @apply text-2xl lg:text-6xl;
}
.post-detail {
  @apply prose-headings:mb-3 prose-p:mb-6 prose-a:text-color00873a prose-a:font-bold prose-ul:mb-6 prose-ol:mb-6 prose-ul:pl-4 prose-ol:pl-4 prose-ul:list-disc prose-ol:list-decimal prose-table:mb-6 prose-th:bg-colorF7F7F7 prose-th:p-3 prose-th:border prose-th:border-color828282 prose-td:p-3 prose-td:border prose-td:border-color828282 prose-td:align-top;
}
.post-detail ul:last-child, .post-detail ol:last-child {
  margin-bottom: 0 !important;
}
.post-detail p:last-child {
  margin-bottom: 0 !important;
}
.post-tag {
  @apply lg:flex items-center;
}
@media (max-width: 991.98px) {
  .post-tag .title {
    @apply mb-4;
  }
}
.post-filter > p {
  @apply font-bold text-color00873a text-rg lg:text-xl;
}
.post-filter .dropdown-toggle {
  @apply border-b border-b-color828282;
}
.post-filter .dropdown-item {
  @apply top-full;
}
.post-category .tagging-list a {
  @apply relative inline-block font-bold text-color828282;
}
.post-category .tagging-list a::before {
  content: "#";
  @apply relative inline-block;
}

@media (max-width: 767.98px) {
  .footer-grid__left-flex {
    @apply flex-wrap;
  }
  .footer-grid__left-flex > div {
    @apply w-1/2;
  }
}
.footer-menu > h5 {
  @apply mb-4 lg:mb-6 lg:text-rg 2xl:text-lg;
}
.footer-menu__address-item > .icon {
  @apply w-6 h-6 mt-[2px];
}
.footer-menu__address-item > p {
  @apply flex-1 pl-4;
}
@media (max-width: 991.98px) {
  .footer-dialog__bottom {
    @apply pb-[96px] !important;
  }
}

@media (max-width: 991.98px) {
  .float {
    @apply bottom-0 left-0 rounded-t-xl;
  }
}
@media (min-width: 992px) {
  .float {
    transform: translateY(-50%);
  }
}
@media (max-width: 991.98px) {
  .float-list {
    @apply flex flex-wrap;
  }
  .float-list > div {
    @apply w-1/5;
  }
}
.float-list__item {
  @apply text-colorffffff;
  transition: background-image 0.25s ease-in-out;
}
@media (max-width: 991.98px) {
  .float-list__item {
    @apply flex-col justify-center mx-auto text-[10px] hover:text-colorffd447;
  }
}
.float-list__item:hover .images {
  @apply scale-125;
}
@media (min-width: 992px) {
  .float-list__item:hover .text {
    @apply opacity-100;
    transform: translateX(-48px);
  }
}
.float-list__item:hover .kefu {
  background-image: url("../images/icon-kefu-hover.svg");
}
.float-list__item:hover .express {
  background-image: url("../images/icon-express-hover.svg");
}
.float-list__item:hover .freight {
  background-image: url("../images/icon-freight-hover.svg");
}
.float-list__item:hover .network {
  background-image: url("../images/icon-network-hover.svg");
}
.float-list__item:hover .order {
  background-image: url("../images/icon-order-hover.svg");
}
@media (max-width: 991.98px) {
  .float-list__item .text {
    @apply mt-1 text-center;
  }
}
@media (min-width: 992px) {
  .float-list__item .text {
    @apply bg-color00873a absolute top-0 right-0 opacity-0 inline-flex items-center justify-center px-3 h-12;
    transform: translateX(100%);
    width: max-content;
  }
}
.float-list__item .kefu {
  background-image: url("../images/icon-kefu.svg");
}
.float-list__item .express {
  background-image: url("../images/icon-express.svg");
}
.float-list__item .freight {
  background-image: url("../images/icon-freight.svg");
}
.float-list__item .network {
  background-image: url("../images/icon-network.svg");
}
.float-list__item .order {
  background-image: url("../images/icon-order.svg");
}

@media (max-width: 991.98px) {
  .main {
    @apply overflow-hidden;
  }
}
@media (max-width: 991.98px) {
  .main.page-index {
    @apply mt-14;
  }
}
.main:not(.page-index) {
  @apply mt-14 lg:mt-19;
}

.notice-dialog {
  @apply whitespace-nowrap lg:px-[200px];
}

@media (max-width: 991.98px) {
  .pointing--coverage .pointing-text {
    order: 1;
  }
}
.pointing--coverage .pointing-text p {
  @apply pr-0 lg:ml-auto lg:w-3/4;
}
.pointing-text h2 {
  @apply mb-4 lg:mb-8 text-color00873a leading-none text-4xl lg:text-[64px] xl:text-[74px] 3xl:text-[100px];
}
.pointing-text p {
  @apply 3xl:text-lg lg:pr-10;
}
@media (max-width: 991.98px) {
  .pointing--coverage .pointing-images {
    order: 2;
  }
}
.pointing-images__image {
  @apply aspect-square overflow-hidden prose-img:w-full prose-img:h-full prose-img:object-cover prose-img:object-center;
}
@media (min-width: 992px) {
  .pointing-images__image {
    aspect-ratio: 1/2;
  }
}
.pointing-images__item {
  @apply absolute top-0 right-0 left-0;
  background: linear-gradient(180deg, hsla(0deg, 0%, 100%, 0), hsla(0deg, 0%, 100%, 0) 40%, rgba(44, 44, 44, 0.96) 77.34%, #222);
  background-blend-mode: multiply;
  transition: all 350ms;
}
.pointing-images__item:hover {
  background: rgba(0, 0, 0, 0.75);
}
.pointing-images__item:hover .title {
  @apply lg:opacity-0;
  transform: translateY(100%);
}
.pointing-images__item:hover .content {
  @apply opacity-100;
  transform: translateY(0);
}
.pointing-images__item .title {
  @apply hidden opacity-0 lg:block lg:opacity-100;
  transition: all 0.3s ease 0.1s;
  transform: translateY(0);
}
.pointing-images__item .title h2 {
  @apply leading-none lg:text-4xl 3xl:text-8xl;
}
.pointing-images__item .content {
  @apply absolute lg:top-20 left-6 right-6 h-full lg:opacity-0;
  transition: all 0.3s ease 0.1s;
}
.pointing-images__item .content h2 {
  @apply mb-8 lg:text-xl 3xl:text-4xl;
}
.pointing-images__item .content p {
  @apply font-light 3xl:text-2xl;
}
@media (max-width: 991.98px) {
  .pointing-images__item .content {
    @apply top-0 flex flex-col items-center justify-end py-8 text-center;
  }
  .pointing-images__item .content h2 {
    @apply mb-4;
  }
}
@media (min-width: 992px) {
  .pointing-images__item .content {
    transform: translateY(100%);
  }
}
.pointing-images__item .btn-call {
  @apply relative mt-8;
}
.pointing-images__item .btn-call:after {
  content: "";
  background-image: url("../images/icon-kefu.svg");
  @apply relative h-5 w-5 ml-2 bg-no-repeat bg-contain;
}

@media (max-width: 767.98px) {
  .wrap-captcha {
    @apply scale-90;
  }
}

@media (max-width: 991.98px) {
  .stray {
    @apply py-8;
  }
}
@media (min-width: 992px) {
  .stray {
    @apply text-left;
    height: calc(100vh - 80px);
  }
}
@media (min-width: 992px) {
  .stray-dialog {
    @apply grid grid-cols-2 gap-16;
  }
}
.stray-image img {
  @apply mx-auto;
  height: 250px;
}
@media (min-width: 992px) {
  .stray-image img {
    height: 500px;
  }
}

@media (max-width: 991.98px) {
  .page-index .tracing-row {
    @apply -m-2;
  }
  .page-index .tracing-row > div {
    @apply p-2;
  }
}
.page-index .tracing-row > div {
  @apply lg:border-r lg:border-r-[#707070];
}
.page-index .tracing-row > div:last-child {
  @apply border-none;
}
.page-index .tracing-row label {
  @apply font-normal text-color828282 text-rg lg:text-lg 2xl:text-xl;
}
.page-index .tracing-form__child button {
  @apply w-16 !important;
}

.services-point > div {
  @apply w-full lg:w-1/3 lg:px-8;
}
.services-point > div + div {
  @apply lg:border-l lg:border-l-colorcbcbcb;
}
@media (max-width: 991.98px) {
  .services-point > div + div {
    @apply border-t border-t-colorcbcbcb;
  }
}
@media (max-width: 991.98px) {
  .services-point {
    @apply -m-3;
  }
  .services-point > div {
    @apply p-3 text-center;
  }
}
.services-status__text h3 {
  @apply lg:text-xl font-normal leading-normal;
}
@media (max-width: 991.98px) {
  .services-status__text h3 {
    @apply text-rg;
  }
}
.services-tracing {
  @apply hidden lg:block;
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.1607843137);
}
@media (max-width: 991.98px) {
  .services-tracing .tracing-row > div {
    @apply p-2;
  }
}
.services-tracing .tracing-row > div {
  @apply lg:border-r lg:border-[#707070];
}
.services-tracing .tracing-row > div:last-child {
  @apply border-none;
}
.services-tracing .tracing-row label {
  @apply font-normal text-color828282 text-rg lg:text-lg 2xl:text-xl;
}

.page-tentang .vision-text h3 {
  @apply lg:text-8xl;
}
.page-tentang .vision-text p {
  @apply lg:text-lg;
}
.page-tentang .vision-row .play-thumb img {
  @apply w-full h-full object-cover object-center;
}
.page-tentang .vision-quality__title h2 {
  @apply mb-6;
}
.page-tentang .vision-quality__title p {
  @apply lg:text-lg;
}
.page-tentang .vision-quality__content > h4 {
  @apply mb-6;
}
.page-tentang .vision-quality__certify img {
  @apply lg:h-12;
}
@media (max-width: 991.98px) {
  .page-tentang .vision-quality__certify {
    @apply flex-wrap;
  }
  .page-tentang .vision-quality__certify > div {
    @apply w-full;
  }
}

.status-scroll {
  @apply h-[380px] lg:h-[calc(480px-40px)] overflow-y-scroll !important;
}
.status-detail__right {
  @apply lg:border-l lg:border-l-color84c686;
}
@media (max-width: 991.98px) {
  .status-detail__right .flow-city h5 {
    @apply text-sm;
  }
}
@media (max-width: 991.98px) {
  .status-detail__right .detail {
    @apply mb-6 pl-6;
  }
  .status-detail__track .status-detail__right .detail {
    @apply border-l border-l-color84c686;
  }
}
.status-detail__right .detail-list__item {
  @apply relative;
}
.status-detail__right .detail-list__item::before {
  content: "";
  @apply bg-colorffffff absolute -left-[34px] lg:-left-16 top-3 bg-no-repeat bg-contain w-5 h-5 border-2 lg:w-8 lg:h-8 lg:border-4 border-colorffffff rounded-full;
  background-image: url("../images/Group-622.svg");
}
.status-flow__item {
  @apply h-16;
}
.status-flow__item.done p {
  @apply bg-color84c686;
}
.status-flow__item.done::after {
  border-left: 10px solid #84C686;
}
.status-flow__item p {
  @apply bg-colorcbcbcb flex-1 flex items-center justify-center h-full;
  margin-right: 10px;
}
.status-flow__item::before, .status-flow__item::after {
  content: "";
  @apply absolute inline-block;
}
.status-flow__item::before {
  @apply left-0;
  border-top: 32px solid transparent;
  border-bottom: 32px solid transparent;
  border-left: 10px solid #fff;
}
.status-flow__item::after {
  @apply right-0;
  border-top: 32px solid transparent;
  border-bottom: 32px solid transparent;
  border-left: 10px solid #CBCBCB;
}
.status-table__header > div {
  @apply lg:w-1/6 text-center p-3 bg-colorcbcbcb text-colorffffff border border-colorffffff;
}
@media (max-width: 991.98px) {
  .status-table__radio {
    @apply mb-6;
  }
}
.status-table__radio-item {
  @apply bg-transparent cursor-pointer mb-0 hover:bg-color84c686;
}
.status-table__radio-item > div {
  @apply flex lg:items-center lg:justify-center lg:text-center lg:w-1/6 p-3 border border-colorcbcbcb -mt-[1px];
}
@media (max-width: 991.98px) {
  .status-table__radio-item > div .title {
    @apply w-2/5;
  }
  .status-table__radio-item > div .title h6 {
    @apply leading-normal;
  }
}
@media (max-width: 991.98px) {
  .status-table__radio-item > div > p {
    @apply pl-4 flex-1;
  }
}
.status-maps__pin-locator {
  @apply bg-colorF7F7F7 w-full h-[350px] lg:h-[480px];
}
.status-maps__empty {
  @apply flex items-center justify-center h-[380px] lg:h-[480px];
}
.status-maps__empty img {
  @apply h-[250px];
}

.member-menu__list-item.active {
  @apply text-color00873a;
}
.member-menu__list-item + div {
  @apply lg:border-t lg:border-t-colorcbcbcb;
}
@media (max-width: 991.98px) {
  .member-menu__list-item + div {
    @apply border-l border-l-colorcbcbcb;
  }
}
.member-scroll {
  @apply lg:h-[450px] lg:overflow-y-scroll;
}
.member-scroll::-webkit-scrollbar {
  @apply w-2 h-3 rounded-full;
}
.member-scroll::-webkit-scrollbar-thumb {
  @apply bg-color828282 rounded-full;
}
.member-content__item {
  @apply border border-colorcbcbcb;
}
.member-content__item .title {
  @apply bg-colorF7F7F7 border-b border-colorcbcbcb;
}
.member-content__item .title-text {
  @apply flex-1 lg:pr-12;
}
.member-content__item .title-text h6 {
  @apply lg:text-lg;
}
.member-content__item .content-list__item p {
  @apply mb-1 text-[#B2B2B2] font-semi;
}
.member-content__item .content-list__item h6 {
  @apply text-color313131;
}
.member-transaction__item-content .pipeline-name {
  @apply lg:w-[200px];
}
.member-transaction__item-content .pipeline-name p {
  @apply mb-2 text-color828282 text-sm;
}
.member-transaction__item-content .pipeline-date p + p {
  @apply border-l border-l-color828282 pl-2 ml-2;
}
.member-transaction__item-content .pipeline-arrow {
  @apply bg-no-repeat bg-contain bg-center lg:w-[140px] h-6;
}
.member-transaction__item-content .pipeline-arrow--left {
  background-image: url("../images/Line-1.png");
}
.member-transaction__item-content .pipeline-arrow--right {
  background-image: url("../images/Arrow-1.png");
}

.order-form__card-header .left > h4 {
  @apply mb-3 lg:mb-0;
}
.order-form__card-header .right > div {
  @apply mt-3 lg:mt-0;
}

.recruitment-title h2 {
  @apply lg:text-7xl text-color313131;
}
.recruitment-list__left {
  @apply sticky top-0 lg:h-[425px];
}
.recruitment-list__left .title {
  @apply flex flex-col justify-center h-21;
}
.recruitment-list__left .listing .active {
  @apply text-color00873a;
}
.recruitment-list__right .listing {
  @apply overflow-y-scroll lg:h-[425px];
}
.recruitment-list__right .listing::-webkit-scrollbar {
  @apply w-2 h-3 rounded-full;
}
.recruitment-list__right .listing::-webkit-scrollbar-thumb {
  @apply bg-color828282 rounded-full;
}
.recruitment-list__right .listing-item__title {
  @apply flex flex-col justify-center h-21;
}
.recruitment-list__right .listing-item__title h5 {
  @apply mb-2;
}

.contact-title h2 {
  @apply text-2xl lg:text-4xl text-color313131;
}
.contact-form h2 {
  @apply text-color313131;
}
@media (max-width: 991.98px) {
  .contact-form h2 {
    @apply text-2xl;
  }
}
.contact-form__point img {
  @apply mb-4 mx-auto h-18 lg:h-21;
}
.contact-form__point h5 {
  @apply font-semi;
}
.contact-step__form-row > div + div::before {
  content: "";
  @apply bg-colorcbcbcb absolute top-0 left-0 w-[2px] h-full;
}
.contact-step__form-row.quantity > div + div::before {
  @apply hidden;
}
.contact-step__form-radio input[type=radio] {
  @apply absolute block top-0 left-0 right-0 appearance-none;
  border: none;
  outline: none;
}
.contact-step__form-radio input[type=radio]:checked + label {
  @apply bg-color313131;
}
.contact-step__form-radio input[type=radio]:checked + label > div {
  @apply bg-color313131;
}
.contact-step__form-radio input[type=radio] + label {
  @apply bg-transparent transition ease-in-out;
}
.contact-step__form-radio input[type=radio] + label img {
  @apply h-[150px] lg:h-[200px] mx-auto;
}
@media (max-width: 991.98px) {
  .contact-step__form-radio input[type=radio] + label h4 {
    @apply text-rg;
  }
}

.mitra-title h2 {
  @apply text-color313131;
}
.mitra-point__item h4 {
  @apply font-normal;
}
@media (max-width: 991.98px) {
  .mitra-point__item h4 {
    @apply text-rg;
  }
}
.mitra-download__title img {
  @apply w-12;
}
.mitra-download__title h4 {
  @apply font-normal;
}
.mitra-form__title h2 {
  @apply text-color313131 lg:text-4xl 3xl:text-8xl;
}

.single-page__menu .item-row + div {
  @apply border-t border-t-colorcbcbcb;
}
.single-page__menu .item-list > h5 {
  @apply mb-4;
}
.single-page__menu .item-list__sub .active a {
  @apply text-color00873a before:bg-color00873a;
}
.single-page__menu .item-list__sub a::before {
  content: "";
  @apply bg-colorcbcbcb relative inline-block w-[6px] h-[6px] mr-2 rounded-full;
}