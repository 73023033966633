@import "~@selectize/selectize/dist/scss/selectize.scss";

.selectize-input,
.selectize-input input,
.status-input .form-custom-selectize .selectize-dropdown {
    line-height: 23px !important
}

.status-input .form-custom-selectize .selectize-dropdown [data-selectable] {
    padding: 2px 10px !important
}

.status-input .form-custom-selectize .selectize-input {
    font-size: 16px !important;
    height: 44px !important;
    padding: 8px 12px !important
}

.status-input .form-custom-selectize .selectize-control.multi .selectize-input>div {
    background: transparent !important;
    border: none !important
}

.selectize {

    &-control {

        .status-input & {
            @apply w-full;
        }
    }

    &-input {
        @apply inline-flex items-center w-full text-color313131 border-[#707070] h-9 lg:h-11 px-3 py-2 text-sm 2xl:text-rg leading-normal shadow-none rounded-none;
        white-space: nowrap;
        overflow: hidden;
        
        .input-material & {
            @apply px-2 border-r-0 border-l-0 border-t-0 border-b border-b-colorcbcbcb;
        }

        .status-input & {
            @apply h-12 lg:h-16 px-6 text-lg;
        }

        &.input-active {

            .status-input & {
                @apply inline-flex #{!important};
            }
        }

        &.focus {
            @apply border-color00873a shadow-none;
        }

        &.dropdown-active {
            @apply rounded-none;
        }

        &::after {
            @apply hidden #{!important};
        }

        input {
            @apply text-color313131 text-sm 2xl:text-rg placeholder:text-color828282;
        }
    }
}