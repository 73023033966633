@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

    :root {
        --offset: 0;
    }

    html {
        @apply text-sm 2xl:text-rg;
    }

    body {
        @apply leading-normal font-body text-color313131;
    }

    h1, h2, h3, h4, h5, h6 {
        @apply font-bold leading-tight;
    }

    h1 {
        @apply text-3xl lg:text-4xl;
    }

    h2 {
        @apply text-2xl lg:text-3xl;
    }

    h3 {
        @apply text-xl lg:text-2xl;
    }

    h4 {
        @apply text-lg lg:text-xl;
    }

    h5 {
        @apply text-rg lg:text-lg;
    }

    h6 {
        @apply text-sm lg:text-rg;
    }

    label {
        @apply inline-block mb-1;
    }

    .container {
        @apply mx-auto px-4;
    }
}