.tree {

    &__open {

        > a {

            > span {
                transform: rotate(-90deg);
            }
        }
    }
}

.treeview {

    > a {
        @apply flex items-center #{!important};

        span {
            @apply ml-auto;
        }
    }

    &__menu {
        @apply hidden;

        > li {
            @apply relative;
        }
    }
}