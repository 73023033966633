.main {

    @include media-breakpoint-down(lg) {
        @apply overflow-hidden;
    }

    &.page-index {

        @include media-breakpoint-down(lg) {
            @apply mt-14;
        }
    }

    &:not(.page-index) {
        @apply mt-14 lg:mt-19;
    }
}