.contact {

    &-title {

        h2 {
            @apply text-2xl lg:text-4xl text-color313131;
        }
    }

    &-form {

        h2 {
            @apply text-color313131;

            @include media-breakpoint-down(lg) {
                @apply text-2xl;
            }
        }

        &__point {

            img {
                @apply mb-4 mx-auto h-18 lg:h-21;
            }

            h5 {
                @apply font-semi;
            }
        }
    }

    &-step {

        &__form {

            &-row {

                > div {

                    + div {
                        
                        &::before {
                            content: '';
                            @apply bg-colorcbcbcb absolute top-0 left-0 w-[2px] h-full;
                        }
                    }
                }

                &.quantity {

                    > div {

                        + div {

                            &::before {
                                @apply hidden;
                            }
                        }
                    }
                }
            }

            &-radio {

                input[type='radio'] {
                    @apply absolute block top-0 left-0 right-0 appearance-none;
                    border: none;
                    outline: none;

                    &:checked {

                        + label {
                            @apply bg-color313131;

                            > div {
                                @apply bg-color313131;
                            }
                        }
                    }

                    + label {
                        @apply bg-transparent transition ease-in-out;

                        img {
                            @apply h-[150px] lg:h-[200px] mx-auto;
                        }

                        h4 {

                            @include media-breakpoint-down(lg) {
                                @apply text-rg;
                            }
                        }
                    }
                }
            }
        }
    }
}