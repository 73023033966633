.status {

    &-scroll {
        @apply h-[380px] lg:h-[calc(480px-40px)] overflow-y-scroll #{!important};
    }

    &-detail {

        &__right {
            @apply lg:border-l lg:border-l-color84c686;

            .flow {

                &-city {

                    h5 {

                        @include media-breakpoint-down(lg) {
                            @apply text-sm;
                        }
                    }
                }
            }

            .detail {

                @include media-breakpoint-down(lg) {
                    @apply mb-6 pl-6;

                    .status-detail__track & {
                        @apply border-l border-l-color84c686;
                    }
                }

                &-list {

                    &__item {
                        @apply relative;

                        &::before {
                            content: '';
                            @apply bg-colorffffff absolute -left-[34px] lg:-left-16 top-3 bg-no-repeat bg-contain w-5 h-5 border-2 lg:w-8 lg:h-8 lg:border-4 border-colorffffff rounded-full;
                            background-image: url('../images/Group-622.svg');
                        }
                    }
                }
            }
        }
    }

    &-flow {

        &__item {
            @apply h-16;
            
            &.done {

                p {
                    @apply bg-color84c686;
                }

                &::after {
                    border-left: 10px solid #84C686;
                }
            }

            p {
                @apply bg-colorcbcbcb flex-1 flex items-center justify-center h-full;
                margin-right: 10px;
            }

            &::before, &::after {
                content: '';
                @apply absolute inline-block;
            }

            &::before {
                @apply left-0;
                border-top: 32px solid transparent;
                border-bottom: 32px solid transparent;
                border-left: 10px solid #fff;
            }

            &::after {
                @apply right-0;
                border-top: 32px solid transparent;
                border-bottom: 32px solid transparent;
                border-left: 10px solid #CBCBCB;
            }
        }
    }

    &-table {

        &__header {

            > div {
                @apply lg:w-1/6 text-center p-3 bg-colorcbcbcb text-colorffffff border border-colorffffff;
            }
        }

        &__radio {

            @include media-breakpoint-down(lg) {
                @apply mb-6;
            }

            &-item {
                @apply bg-transparent cursor-pointer mb-0 hover:bg-color84c686;
                
                > div {
                    @apply flex lg:items-center lg:justify-center lg:text-center lg:w-1/6 p-3 border border-colorcbcbcb -mt-[1px];

                    .title {

                        @include media-breakpoint-down(lg) {
                            @apply w-2/5;

                            h6 {
                                @apply leading-normal;
                            }
                        }
                    }

                    > p {
                        
                        @include media-breakpoint-down(lg) {
                            @apply pl-4 flex-1;
                        }
                    }
                }
            }
        }
    }

    &-maps {

        &__pin {

            &-locator {
                @apply bg-colorF7F7F7 w-full h-[350px] lg:h-[480px];
            }
        }

        &__empty {
            @apply flex items-center justify-center h-[380px] lg:h-[480px];

            img {
                @apply h-[250px];
            }
        }
    }
}