.navbar {
    @apply bg-colorffffff h-14 lg:h-19;
    transition: ease-in-out .25s, height .25s, opacity .25s;

    &--page {

        &-index {
            @apply lg:bg-color000000 lg:bg-opacity-25 text-colorffffff;

            .navbar {

                &-menu {
                    @apply hidden lg:flex;
            
                    &__list {
            
                        a, .dropdown-toggle {
                            @apply hover:text-colorffd447;
                        }
                    }
                }
            }
        }
    }

    &--fixed {
        @apply bg-colorffffff shadow-rg lg:h-14;

        .navbar {

            &-menu {
                @apply hidden lg:flex;
        
                &__list {
        
                    a, .dropdown-toggle {
                        @apply hover:text-color00873a;
                    }
                }
            }
        }
    }

    &-logo {
        @apply text-color00873a;

        .navbar--page-index & {
            @apply lg:text-colorffffff;
        }

        .navbar--fixed & {
            @apply text-color00873a;
        }
    }

    &-hamburger {
        @apply flex lg:hidden;

        &__btn {

            > span {
                @apply bg-color313131 block w-6 transition ease-in-out rotate-0;
                height: 1.5px;

                &:nth-child(2) {
                    @apply w-4;
                }
    
                + span {
                    @apply mt-2;
                }
            }
    
            &.show {
    
                > span {
        
                    &:nth-child(1) {
                        margin-top: 0;
                        transform: rotate(135deg);
                    }
        
                    &:nth-child(2) {
                        opacity: 0;
                    }
        
                    &:nth-child(3) {
                        @apply w-6;
                        margin-top: -10.5px;
                        transform: rotate(-135deg);
                    }
              }
            }
        }
    }

    &-menu {
        @apply hidden lg:flex;

        .navbar--fixed & {
            @apply text-color313131;
        }

        &__list {

            a, .dropdown-toggle {
                @apply hover:text-color00873a;
            }
        }
    }

    &-search {

        @include media-breakpoint-up(lg) {
            @apply ml-8;
        }

        &__btn {

            .close {
                @apply hidden;
    
                .searchbox-open & {
                    @apply block;
                }
            }
    
            .search {
    
                .searchbox-open & {
                    @apply hidden;
                }
            }
        }
    }
}