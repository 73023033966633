.splide {

    &__track {

        .splide--sla &, .splide--coverage &, .splide--service & {
            
            @include media-breakpoint-down(md) {
                overflow: unset;
            }
        }
    }

    &__slide {

        .splide--testimonial-thumbnail & {
            @apply flex items-center;

            &:not(.is-active) {

                img {
                    @apply grayscale scale-[.8];
                }
            }

            &.is-active {

                img {
                    @apply scale-150;
                }
            }
        }
    }

    &__item {

        .splide--home & {
            @apply block sm:aspect-square md:aspect-square lg:w-full lg:h-[calc(100vh-200px)] overflow-hidden;

            img {
                @apply w-full h-full object-cover object-center;
            }
        }
    }

    &__arrows {
        @apply absolute flex items-center justify-center w-full z-10;

        .splide--home & {
            @apply lg:bottom-12 left-0 right-0;

            @include media-breakpoint-down(lg) {
                @apply top-1/2;
                transform: translateY(-50%);
            }
        }

        .splide--service & {
            @apply top-1/2;
            transform: translateY(-50%);
        }

        .splide--testimonial-main & {
            @apply hidden;
        }

        .splide--testimonial-thumbnail & {
            @apply relative;
        }
    }

    &__arrow {
        @apply bg-color00873a w-8 h-8 lg:w-12 lg:h-12 flex items-center justify-center mx-2 rounded-full overflow-hidden;

        &[disabled] {
            @apply bg-colorF7F7F7 bg-opacity-50 cursor-not-allowed;
        }

        .splide--home & {

            @include media-breakpoint-down(lg) {
                @apply absolute;
            }
        }

        .splide--service & {
            @apply absolute;

            &[disabled] {
                @apply bg-color828282;
            }
        }

        .splide--testimonial-thumbnail & {
            @apply bg-color828282 mt-4 lg:mt-10;

            &[disabled] {
                @apply bg-colorcbcbcb;
            }
        }

        &--prev {

            .splide--home & {
                @apply -left-1;
            }

            .splide--service & {
                @apply -left-5 lg:-left-8;
            }
        }

        &--next {

            .splide--home & {
                @apply -right-1;
            }

            .splide--service & {
                @apply -right-5 lg:-right-8;
            }
        }
    }
}