.pointing {
        
    &-text {

        .pointing--coverage & {

            @include media-breakpoint-down(lg) {
                order: 1;
            }

            p {
                @apply pr-0 lg:ml-auto lg:w-3/4;
            }
        }

        h2 {
            @apply mb-4 lg:mb-8 text-color00873a leading-none text-4xl lg:text-[64px] xl:text-[74px] 3xl:text-[100px];;
        }

        p {
            @apply 3xl:text-lg lg:pr-10;
        }
    }

    &-images {

        .pointing--coverage & {

            @include media-breakpoint-down(lg) {
                order: 2;
            }
        }

        &__image {
            @apply aspect-square overflow-hidden prose-img:w-full prose-img:h-full prose-img:object-cover prose-img:object-center;

            @include media-breakpoint-up(lg) {
                aspect-ratio: 1/2;
            }
        }

        &__item {
            @apply absolute top-0 right-0 left-0;
            background: linear-gradient(180deg,hsla(0,0%,100%,0),hsla(0,0%,100%,0) 40%,rgba(44,44,44,0.96) 77.34%,#222);
            background-blend-mode: multiply;
            transition: all 350ms;

            &:hover {
                background: rgba($color: #000000, $alpha: .75);

                .title {
                    @apply lg:opacity-0;
                    transform: translateY(100%);
                }

                .content {
                    @apply opacity-100;
                    transform: translateY(0);
                }
            }

            .title {
                @apply hidden opacity-0 lg:block lg:opacity-100;
                transition: all 0.3s ease 0.1s;
                transform: translateY(0);

                h2 {
                    @apply leading-none lg:text-4xl 3xl:text-8xl;
                }
            }

            .content {
                @apply absolute lg:top-20 left-6 right-6 h-full lg:opacity-0;
                transition: all 0.3s ease 0.1s;

                h2 {
                    @apply mb-8 lg:text-xl 3xl:text-4xl;
                }

                p {
                    @apply font-light 3xl:text-2xl;
                }

                @include media-breakpoint-down(lg) {
                    @apply top-0 flex flex-col items-center justify-end py-8 text-center;

                    h2 {
                        @apply mb-4;
                    }
                }

                @include media-breakpoint-up(lg) {
                    transform: translateY(100%);
                }
            }

            .btn-call {
                @apply relative mt-8;

                &:after {
                    content: '';
                    background-image: url('../images/icon-kefu.svg');
                    @apply relative h-5 w-5 ml-2 bg-no-repeat bg-contain;
                }
            }
        }
    }
}