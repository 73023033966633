.jumbotron {
    @apply h-[calc(200px+10vw)];

    &--about, &--order, &--services {
        @apply text-colorffffff;
    }

    &--services {
        
        .jumbotron-images {
            
            &::before {
                content: '';
                @apply absolute top-0 right-0 left-0 h-full;
                background: linear-gradient(90deg, rgba(56,56,56,1) -15%, rgba(255,255,255,1) 100%);
                mix-blend-mode: multiply;
            }
        }
    }

    &-images {
        @apply w-full;
        
        img {
            @apply w-full h-full object-cover object-top;
        }
    }

    &-body {

        h2 {
            @apply leading-none lg:text-7xl;
        }

        p {
            @apply mt-4 lg:text-lg;
        }

        .jumbotron--mitra-footer & {
            @apply sm:w-full w-1/3 lg:pr-21;
            
            p {
                @apply lg:text-xl;
            }
        }
    }

    &-tag {

        h4 {

            @include media-breakpoint-down(lg) {
                @apply text-rg;
            }
        }
    }
}