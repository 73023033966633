.recruitment {

    &-title {
        
        h2 {
            @apply lg:text-7xl text-color313131;
        }
    }

    &-list {

        &__left {
            @apply sticky top-0 lg:h-[425px];
            
            .title {
                @apply flex flex-col justify-center h-21;
            }

            .listing {

                .active {
                    @apply text-color00873a;
                }
            }
        }

        &__right {

            .listing {
                @apply overflow-y-scroll lg:h-[425px];
    
                &::-webkit-scrollbar {
                    @apply w-2 h-3 rounded-full;
                }
                
                &::-webkit-scrollbar-thumb {
                    @apply bg-color828282 rounded-full;
                }

                &-item {

                    &__title {
                        @apply flex flex-col justify-center h-21;
                        
                        h5 {
                            @apply mb-2;
                        }
                    }
                }
            }
        }
    }
}