.page-index {

    .tracing {

        &-row {

            @include media-breakpoint-down(lg) {
                @apply -m-2;

                > div {
                    @apply p-2;
                }
            }
    
            > div {
                @apply lg:border-r lg:border-r-[#707070];
                
                &:last-child {
                    @apply border-none;
                }
            }
    
            label {
                @apply font-normal text-color828282 text-rg lg:text-lg 2xl:text-xl;
            }
        }
    
        &-form {
    
            &__child {
    
                button {
                    @apply w-16 #{!important};
                }
            }
        }
    }
}