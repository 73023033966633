.order {

    &-form {

        &__card {
            
            &-header {

                .left {

                    > h4 {
                        @apply mb-3 lg:mb-0;
                    }
                }

                .right {

                    > div {
                        @apply mt-3 lg:mt-0;
                    }
                }
            }
        }
    }
}