.accordion {

    &-item {

        &:not(:first-of-type) {
            @apply border-t-0;
        }
    }

    &-toggle {

        &[aria-expanded=true] {
            background-color: inherit;
            transition-duration: .75s;
            transition-property: background-color, border-color;
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

            .icon {

                svg {
                    @apply rotate-180;
                }
            }
        }

        &[aria-expanded=false] {
            transition-duration: .75s;
            transition-property: background-color, border-color;
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        h6 {

            .accordion--recruitment & {
                @apply font-bold;
            }

            @include media-breakpoint-down(md) {
                @apply text-sm;
            }
        }

        .icon {

            svg {
                @apply h-3 fill-color000000;
                transition-duration: .5s;
                transition-property: transform;
                transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            }
        }
    }

    &-content {
        transition-duration: .5s;
        transition-property: transform, opacity;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

        &[aria-hidden=true] {
            height: 0;
            opacity: 0;
            transform: translate3d(0, -0.5rem, 0);
            visibility: hidden;
        }

        &[aria-hidden=false] {
            height: auto;
            opacity: 1;
            transform: translate3d(0, 0, 0);
            visibility: visible;
        }
    }
}