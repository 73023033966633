.card {

    &--services {
        box-shadow: 0px 20px 6px #00000029;

        &::before {
            content: '';
            @apply absolute bg-color313131 bottom-0 right-0 left-0 h-4 transition ease-in-out;
        }

        &:hover {

            &::before {
                @apply bg-color00873a;
            }

            h3, .actions {
                @apply text-color00873a;
            }

            svg {
                @apply fill-color00873a;
            }
        }

        &-related {
            @apply block #{!important};
        }
    }

    &--maps {
        box-shadow: 0px 3px 6px #00000029;
    }

    &--point {

        &-about {
            @apply flex flex-col items-center justify-center lg:h-[275px];
        }
    }
    
    &-header {

        .card--services-index & {
            @apply lg:w-[175px] lg:h-full;

            @include media-breakpoint-down(lg) {
                
                .images {
                    @apply bg-top h-[175px];
                }
            }
        }

        .card--services-related & {
            @apply lg:flex-col lg:items-start;

            h3 {
                @apply leading-none text-rg lg:order-2 lg:text-xl;
            }

            .images {
                @apply bg-top lg:order-1 lg:ml-0 h-[200px];
            }
        }

        .card--testimonial & {

            img {
                @apply h-13;
            }
        }

        .card--point-services & {
            @apply w-8 lg:w-20;

            img {
                @apply w-8 lg:w-20;
            }
        }

        .card--point-about & {

            img {
                @apply mx-auto h-14 lg:h-21;
            }
        }

        .card--news & {
            @apply w-[100px] h-[100px] lg:w-[300px] lg:h-[175px];

            img {
                @apply object-cover object-center w-full h-full;
            }
        }
    }

    &-content {

        .card--services & {

            h3 {
                @apply mb-2 text-color00873a leading-none text-rg lg:text-3xl 2xl:text-4xl;
            }

            .entry {
                
                p {
                    @apply line-clamp-4;
                }
            }
        }

        .card--services-index & {

            @include media-breakpoint-down(lg) {
                @apply pb-0;
            }

            @include media-breakpoint-up(lg) {
                @apply pr-0;
            }
        }

        .card--services-related & {
            @apply lg:p-6 pb-0;

            .entry {
                
                p {
                    @apply line-clamp-3 h-[63px] lg:line-clamp-5 lg:h-[98px];
                }
            }

            .actions {
                @apply w-full;

                span {
                    @apply ml-0;
                }
            }
        }

        .card--testimonial & {
            
            .caption {

                h6 {
                    @apply font-normal lg:text-lg;
                }
            }
        }

        .card--point-services & {

            h3 {
                @apply font-normal text-rg lg:text-lg;
            }
        }

        .card--point-about & {

            h3 {
                @apply lg:text-6xl;
            }
        }

        .card--news & {

            > h2 {
                @apply lg:my-3 text-rg lg:text-lg line-clamp-2 lg:h-[50px];

                @include media-breakpoint-down(lg) {
                    @apply mt-3;
                }
            }

            .info {
                @apply text-sm;

                &-tag {
                    @apply p-[2px] lg:p-4 font-bold text-color00873a;
                }

                &-date {
                    @apply p-[2px] lg:p-4 text-colorcbcbcb;
                }
            }

            .excerpt {

                p {
                    @apply line-clamp-2;
                    height: 48px;
                }
            }
        }

        .card--member & {

            .title {
                @apply relative lg:w-[130px] text-color828282;
                
                &::before {
                    content: ':';
                    @apply absolute right-0 top-0;
                }
            }

            .content {
                @apply flex-1 text-color828282 lg:pl-2;
            }
        }

        .card--member-informasi & {
            
            .title {
                @apply lg:w-auto;

                &::before {
                    content: '';
                }
            }

            .content {
                @apply pr-6 lg:text-right;
            }
        }

        .card--maps & {

            .images {

                img {
                    @apply w-full h-full object-cover object-center;
                }
            }

            .content {

                &-address {

                    p {
                        @apply line-clamp-2;
                    }
                }

                h6 {
                    @apply mb-1;
                }
            }
        }

        .card--quality & {

            p {
                @apply mt-4 lg:text-lg;
            }
        }
    }
}