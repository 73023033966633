.float {

    @include media-breakpoint-down(lg) {
        @apply bottom-0 left-0 rounded-t-xl;
    }
    
    @include media-breakpoint-up(lg) {
        transform: translateY(-50%);
    }

    &-list {

        @include media-breakpoint-down(lg) {
            @apply flex flex-wrap;

            > div {
                @apply w-1/5;
            }
        }

        &__item {
            @apply text-colorffffff;
            transition: background-image .25s ease-in-out;

            @include media-breakpoint-down(lg) {
                @apply flex-col justify-center mx-auto text-[10px] hover:text-colorffd447;
            }

            &:hover {
                
                .images {
                    @apply scale-125;
                }

                .text {

                    @include media-breakpoint-up(lg) {
                        @apply opacity-100;
                        transform: translateX(-48px);
                    }
                }

                .kefu {
                    background-image: url('../images/icon-kefu-hover.svg');
                }
    
                .express {
                    background-image: url('../images/icon-express-hover.svg');
                }
    
                .freight {
                    background-image: url('../images/icon-freight-hover.svg');
                }
    
                .network {
                    background-image: url('../images/icon-network-hover.svg');
                }
    
                .order {
                    background-image: url('../images/icon-order-hover.svg');
                }
            }

            .text {

                @include media-breakpoint-down(lg) {
                    @apply mt-1 text-center;
                }
                
                @include media-breakpoint-up(lg) {
                    @apply bg-color00873a absolute top-0 right-0 opacity-0 inline-flex items-center justify-center px-3 h-12;
                    transform: translateX(100%);
                    width: max-content;
                }
            }

            .kefu {
                background-image: url('../images/icon-kefu.svg');
            }

            .express {
                background-image: url('../images/icon-express.svg');
            }

            .freight {
                background-image: url('../images/icon-freight.svg');
            }

            .network {
                background-image: url('../images/icon-network.svg');
            }

            .order {
                background-image: url('../images/icon-order.svg');
            }
        }
    }
}