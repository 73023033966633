.footer {

    &-grid {

        &__left {

            &-flex {

                @include media-breakpoint-down(md) {
                    @apply flex-wrap;

                    > div {
                        @apply w-1/2;
                    }
                }
            }
        }
    }
    
    &-menu {

        > h5 {
            @apply mb-4 lg:mb-6 lg:text-rg 2xl:text-lg;
        }

        &__address-item {

            > .icon {
                @apply w-6 h-6 mt-[2px];
            }

            > p {
                @apply flex-1 pl-4;
            }
        }
    }

    &-dialog {

        &__bottom {
            
            @include media-breakpoint-down(lg) {
                @apply pb-[96px] #{!important};
            }
        }
    }
}