.dropdown {
    @apply relative;

    &.active {
        
        .dropdown {

            &-toggle {

                .icon-down {
                    @apply rotate-180;
                    transition: transform .25s;
                }
            }

            &-item {
                @apply block;
            }
        }
    }

    &-toggle {
        @apply flex items-center cursor-pointer;
    }

    &-item {
        @apply bg-colorffffff absolute hidden top-14 min-w-[175px] h-auto rounded-sm overflow-hidden;
        box-shadow: 0 1px 5px rgba(0,0,0,0.2), 0 2px 2px rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12);

        &--end {
            @apply right-6;
        }

        a {
            @apply text-color313131 hover:text-color00873a;
        }

        .navbar--fixed & {
            @apply top-[38px]
        }
    }
}