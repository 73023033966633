$font-path: "../fonts";

@font-face {
	font-display: swap;
	font-family: 'iconfont';
	src: url("#{$font-path}/iconfont.b0a066e5.woff2");
	src: url("#{$font-path}/iconfont.b0a066e5.woff2") format('truetype');
}

.icon {
    font-family: 'iconfont';
    font-style: normal;
    -webkit-font-smoothing: antialiased;

    &-logo {

        &::before {
            content: "\e74b";
        }
    }

    &-user {

        &::before {
            content: "\e734";
        }
    }

    &-globe {

        &::before {
            content: "\e736";
        }
    }

    &-search {

        &::before {
            content: "\e742";
        }
    }

    &-down {

        &::before {
            content: "\e735";
        }
    }

    &-pin {

        &::before {
            content: "\e7b4";
        }
    }

    &-phone {

        &::before {
            content: "\e7b6";
        }
    }
}

.icon-shangchuantupian:before {
    content: "\e6a2"
}

.icon-rukou_shixiaochaxun:before {
    content: "\e7f3"
}

.icon-LOGO-WhatsAPP:before {
    content: "\e7d4"
}

.icon-mp_denglu-gougou:before {
    content: "\e7a3"
}

.icon-a-icon-jijianshoujian:before {
    content: "\e7ca"
}

.icon-yunluruanjianfuwuxinxi-hengban-yingwen:before {
    content: "\e782"
}

.icon-yunlukejilogo:before {
    content: "\e780"
}

.icon-yunluruanjianfuwuxinxi-hengban-zhongwen:before {
    content: "\e781"
}

.icon-icozhiding:before {
    content: "\e77f"
}

.icon-gouxuan:before {
    content: "\e77d"
}

.icon-weigouxuan:before {
    content: "\e77e"
}

.icon-jiantou:before {
    content: "\e77b"
}

.icon-jiantoushang:before {
    content: "\e77c"
}

.icon-daohang_fanhui:before {
    content: "\e773"
}

.icon-daohang_guanbi:before {
    content: "\e774"
}

.icon-daohang_caidan:before {
    content: "\e775"
}

.icon-daohang_denglu:before {
    content: "\e776"
}

.icon-daohang_zhuye:before {
    content: "\e77a"
}

.icon-gerenzhongxin_wodedingdan:before {
    content: "\e777"
}

.icon-gerenzhongxin_tongxunlu:before {
    content: "\e778"
}

.icon-gerenzhongxin_gerenxinxi:before {
    content: "\e779"
}

.icon-icoJFSyunyinghexinxitong:before {
    content: "\e771"
}

.icon-icokefugongdanguanlixitong:before {
    content: "\e76b"
}

.icon-icoguanlibaobiaoleixitong:before {
    content: "\e76c"
}

.icon-icohujiaozhongxinxitong:before {
    content: "\e76d"
}

.icon-icoshouchizhongduanguanlixitong:before {
    content: "\e76e"
}

.icon-icokehuguanxiguanlixitong:before {
    content: "\e76f"
}

.icon-icoyunliguanlixitong:before {
    content: "\e770"
}

.icon-icofenjianzhichixitong:before {
    content: "\e772"
}

.icon-icozaixiankefu:before {
    content: "\e7c8"
}

.icon-biaozhunkuaiyun_bai:before {
    content: "\e7c7"
}

.icon-xiaohuotong_bai:before {
    content: "\e7c6"
}

.icon-shixiaofuwu_bai:before {
    content: "\e7c5"
}

.icon-icon_taiguo:before {
    content: "\e763"
}

.icon-icon_xinjiapo:before {
    content: "\e764"
}

.icon-icon_yuenan:before {
    content: "\e765"
}

.icon-icon_malai:before {
    content: "\e766"
}

.icon-icon_zhongguo:before {
    content: "\e767"
}

.icon-icon_feilvbin:before {
    content: "\e768"
}

.icon-icon_yinni:before {
    content: "\e769"
}

.icon-icon_jianpuzhai:before {
    content: "\e76a"
}

.icon-icon-fenjian:before {
    content: "\e7c0"
}

.icon-icon-zhuanyun:before {
    content: "\e7c1"
}

.icon-icon-cheliang:before {
    content: "\e7c2"
}

.icon-icon-yuangong:before {
    content: "\e7c3"
}

.icon-icon-wangdian:before {
    content: "\e7c4"
}

.icon-anquanbaozhuang:before {
    content: "\e7b8"
}

.icon-cangpeiyiti:before {
    content: "\e7b9"
}

.icon-qiandanfanhuan:before {
    content: "\e7ba"
}

.icon-huowubaojia:before {
    content: "\e7bb"
}

.icon-dingshipaisong:before {
    content: "\e7bc"
}

.icon-daishouhuokuan:before {
    content: "\e7bd"
}

.icon-rucangfuwu:before {
    content: "\e7be"
}

.icon-weituoshouhuo:before {
    content: "\e7bf"
}

.icon-icon-xinxiqipao:before {
    content: "\e7b7"
}

.icon-icon-dizhi:before {
    content: "\e7b4"
}

.icon-icon-shijian:before {
    content: "\e7b5"
}

.icon-icon-dianhua:before {
    content: "\e7b6"
}

.icon-icon-shengshiqu:before {
    content: "\e7b3"
}

.icon-icon-cuowutishi:before {
    content: "\e7b1"
}

.icon-icon-chenggongtishi:before {
    content: "\e7b2"
}

.icon-icon-wancheng:before {
    content: "\e7b0"
}

.icon-icon-daoda:before {
    content: "\e7af"
}

.icon-bianji:before {
    content: "\e762"
}

.icon-xinzeng:before {
    content: "\e761"
}

.icon-danchuangfanhui:before {
    content: "\e75f"
}

.icon-danchuangguanbi:before {
    content: "\e760"
}

.icon-dengluzhuce_mimayincang:before {
    content: "\e75d"
}

.icon-dengluzhuce_mimakeshi:before {
    content: "\e75e"
}

.icon-xinxi_3ji:before {
    content: "\e754"
}

.icon-xinxi_2ji:before {
    content: "\e755"
}

.icon-xinxi_4ji:before {
    content: "\e756"
}

.icon-xinxi_5ji:before {
    content: "\e757"
}

.icon-xinxi_6ji:before {
    content: "\e758"
}

.icon-xinxi_7ji:before {
    content: "\e759"
}

.icon-xinxi_1ji:before {
    content: "\e75a"
}

.icon-xinxi_9ji:before {
    content: "\e75b"
}

.icon-xinxi_8ji:before {
    content: "\e75c"
}

.icon-xinxi_baozhuangcailiaobudang:before {
    content: "\e74d"
}

.icon-xinxi_qiguan:before {
    content: "\e74e"
}

.icon-xinxi_falvjinzhi:before {
    content: "\e74f"
}

.icon-xinxi_dupin:before {
    content: "\e750"
}

.icon-xinxi_xuanchuancailiao:before {
    content: "\e751"
}

.icon-xinxi_yiranwu:before {
    content: "\e752"
}

.icon-xinxi_qiangzhi:before {
    content: "\e753"
}

.icon-icon-houtui:before {
    content: "\e79c"
}

.icon-icon-tongxunlu:before {
    content: "\e79e"
}

.icon-icon-qingkong:before {
    content: "\e79f"
}

.icon-icon-weixuan:before {
    content: "\e7a0"
}

.icon-icon-qianjin:before {
    content: "\e7a1"
}

.icon-icon-xuanzhong:before {
    content: "\e7a2"
}

.icon-a-shixiaofuwu1x:before {
    content: "\e744"
}

.icon-a-xiaohuotong1x:before {
    content: "\e74a"
}

.icon-a-biaozhunkuaiyun1x:before {
    content: "\e74c"
}

.icon-a-banner_JTCargo:before {
    content: "\e743"
}

.icon-logo:before {
    content: "\e74b"
}

.icon-shouye_fuwubaozhang_bianjiexiadan:before {
    content: "\e73c"
}

.icon-shouye_fuwubaozhang_biaozhunguanli:before {
    content: "\e73d"
}

.icon-shouyedibu_youxiang:before {
    content: "\e73e"
}

.icon-shouye_fuwubaozhang_quanchenggenzong:before {
    content: "\e73f"
}

.icon-shouyedibu_dizhi:before {
    content: "\e740"
}

.icon-shouye_fuwubaozhang_lipeibaozhang:before {
    content: "\e741"
}

.icon-sousuo_chaxunyundan:before {
    content: "\e742"
}

.icon-shouyedibu_rexian:before {
    content: "\e745"
}

.icon-zengzhifuwu_gou:before {
    content: "\e746"
}

.icon-shouye_fuwubaozhang_zhuanyeyunzuo:before {
    content: "\e747"
}

.icon-shouye_fuwubaozhang_jisuxiangying:before {
    content: "\e748"
}

.icon-zengzhifuwu:before {
    content: "\e749"
}

.icon-fuwubaozhang_shangyiye:before {
    content: "\e738"
}

.icon-fuwubaozhang_xiayiye:before {
    content: "\e73a"
}

.icon-gengduo:before {
    content: "\e737"
}

.icon-rukou_zaixianjijian:before {
    content: "\e739"
}

.icon-rukou_wangdianchaxun:before {
    content: "\e73b"
}

.icon-daohang_icoyuyan:before {
    content: "\e736"
}

.icon-daohang_jiantoushouqi:before {
    content: "\e733"
}

.icon-daohang_icodengluzhuce:before {
    content: "\e734"
}

.icon-daohang_jiantouxiala:before {
    content: "\e735"
}