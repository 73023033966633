.services {

    &-point {

        > div {
            @apply w-full lg:w-1/3 lg:px-8;

            + div {
                @apply lg:border-l lg:border-l-colorcbcbcb;

                @include media-breakpoint-down(lg) {
                    @apply border-t border-t-colorcbcbcb;
                }
            }
        }

        @include media-breakpoint-down(lg) {
            @apply -m-3;

            > div {
                @apply p-3 text-center;
            }
        }
    }

    &-status {

        &__text {

            h3 {
                @apply lg:text-xl font-normal leading-normal;
            }

            @include media-breakpoint-down(lg) {

                h3 {
                    @apply text-rg;
                }
            }
        }
    }

    &-tracing {
        @apply hidden lg:block;
        box-shadow: 0px 20px 50px #00000029;

        .tracing {

            &-row {

                @include media-breakpoint-down(lg) {
    
                    > div {
                        @apply p-2;
                    }
                }

                > div {
                    @apply lg:border-r lg:border-[#707070];
                
                    &:last-child {
                        @apply border-none;
                    }
                }

                label {
                    @apply font-normal text-color828282 text-rg lg:text-lg 2xl:text-xl;
                }
            }
        }
    }
}