.media {

    &-content {

        .media--info & {

            h6 {
                @apply mb-1 font-normal;
            }

            p {
                @apply text-[#666];
            }
        }
    }
}