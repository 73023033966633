.post {

    &-title {
        
        h1 {
            @apply text-2xl lg:text-6xl;
        }
    }

    &-detail {
        @apply prose-headings:mb-3 prose-p:mb-6 prose-a:text-color00873a prose-a:font-bold prose-ul:mb-6 prose-ol:mb-6 prose-ul:pl-4 prose-ol:pl-4 prose-ul:list-disc prose-ol:list-decimal prose-table:mb-6 prose-th:bg-colorF7F7F7 prose-th:p-3 prose-th:border prose-th:border-color828282 prose-td:p-3 prose-td:border prose-td:border-color828282 prose-td:align-top;

        ul, ol {

            &:last-child {
                margin-bottom: 0 #{!important};
            }
        }

        p {

            &:last-child {
                margin-bottom: 0 #{!important};
            }
        }
    }

    &-tag {
        @apply lg:flex items-center;

        .title {
            
            @include media-breakpoint-down(lg) {
                @apply mb-4;
            }
        }
    }

    &-filter {

        > p {
            @apply font-bold text-color00873a text-rg lg:text-xl;
        }

        .dropdown {

            &-toggle {
                @apply border-b border-b-color828282;
            }

            &-item {
                @apply top-full;
            }
        }
    }

    &-category {

        .tagging {

            &-list {

                a {
                    @apply relative inline-block font-bold text-color828282;

                    &::before {
                        content: '#';
                        @apply relative inline-block;
                    }
                }
            }
        }
    }
}