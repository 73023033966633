.tab {

    &-link {

        &[aria-selected='true'] {

            .tab--member &, .tab--single & {
                @apply text-color00873a border-b-color00873a;
            }
        }
    }
}