.section {

    &--service {

        @include media-breakpoint-down(lg) {
            background-size: 150% !important;
            background-position: right top !important;
        }
    }

    &--testimonial {

        @include media-breakpoint-down(lg) {
            background-image: url('../images/BG-Testimoni-Kami@2x-mobile.png') !important;
            background-size: 110% !important;
            background-position: center top !important;
        }
    }

    &-title {

        h2 {
            @apply text-color000000 text-7xl lg:text-[62px];
        }

        @include media-breakpoint-down(lg) {
            @apply px-8 #{!important};
        }
    }
}