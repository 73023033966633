.stray {

    @include media-breakpoint-down(lg) {
        @apply py-8;
    }

    @include media-breakpoint-up(lg) {
        @apply text-left;
        height: calc(100vh - 80px);
    }

    &-dialog {

        @include media-breakpoint-up(lg) {
            @apply grid grid-cols-2 gap-16;
        }
    }

    &-image {

        img {
            @apply mx-auto;
            height: 250px;

            @include media-breakpoint-up(lg) { 
                height: 500px; 
            }
        }
    }
}