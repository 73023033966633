@keyframes checkmark {
    0% {
        background-position-y: 5px;
    }
    50% {
        background-position-y: -2px;
    }
    100% {
        background-position-y: 0;
    }
}

.form-check {

    .status-input__radio & {
        @apply relative inline-flex items-center cursor-pointer;

        input[type='radio'] {
            @apply relative w-4 h-4 rounded-full appearance-none outline-none cursor-pointer;
            border: 1px solid #828282;
            transition: .25s;

            &:checked {
                @apply bg-color00873a border-color00873a;
                animation: checkmark .2s ease-in;
            }
        }

        label {
            @apply inline-block flex-1 pl-2 mb-0 cursor-pointer;
        }
    }
}