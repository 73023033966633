.popup {
    @apply bg-color000000 bg-opacity-50 fixed top-0 left-0 w-full h-full overflow-x-hidden overflow-y-auto;
    z-index: 1056;
    
    &-dialog {
        @apply relative flex items-center mx-16 lg:mx-auto lg:max-w-[300px];
        min-height: calc(100% - 1.75rem * 2);
    }

    &-content {
        @apply bg-colorffffff relative flex flex-col w-full;
    }
}

.modal {

    &-dialog {

        .modal-outlet & {
            @apply lg:max-w-[750px];
        }
    }

    &-body {

        .status {

            &-outlet {

                &__list {
                    @apply lg:pr-4 h-[540px] lg:h-[400px] overflow-y-scroll;

                    &::-webkit-scrollbar {
                        @apply w-2 h-3 rounded-full;
                    }
                    
                    &::-webkit-scrollbar-thumb {
                        @apply bg-color828282 rounded-full;
                    }

                    &-item {
                        @apply mb-4 pb-4 lg:mb-6 lg:pb-6 border-b border-b-colorcbcbcb;
                    }

                    .title {
                        @apply relative;

                        &::after {
                            content: ':';
                            @apply absolute top-0 right-0;
                        }
                    }
                }
            }
        }
    }
}