.single-page {

    &__menu {

        .item {

            &-row {

                + div {
                    @apply border-t border-t-colorcbcbcb;
                }
            }

            &-list {

                > h5 {
                    @apply mb-4;
                }

                &__sub {

                    .active {

                        a {
                            @apply text-color00873a before:bg-color00873a;
                        }
                    }
                
                    a {
    
                        &::before {
                            content: '';
                            @apply bg-colorcbcbcb relative inline-block w-[6px] h-[6px] mr-2 rounded-full;
                        }
                    }
                }
            }
        }
    }
}