.member {

    &-menu {

        &__list {

            &-item {

                &.active {
                    @apply text-color00873a;
                }

                + div {
                    @apply lg:border-t lg:border-t-colorcbcbcb;

                    @include media-breakpoint-down(lg) {
                        @apply border-l border-l-colorcbcbcb;
                    }
                }
            }
        }
    }

    &-scroll {
        @apply lg:h-[450px] lg:overflow-y-scroll;

        &::-webkit-scrollbar {
            @apply w-2 h-3 rounded-full;
        }
        
        &::-webkit-scrollbar-thumb {
            @apply bg-color828282 rounded-full;
        }
    }

    &-content {

        &__item {
            @apply border border-colorcbcbcb;

            .title {
                @apply bg-colorF7F7F7 border-b border-colorcbcbcb;

                &-text {
                    @apply flex-1 lg:pr-12;

                    h6 {
                        @apply lg:text-lg;
                    }
                }
            }

            .content {

                &-list {

                    &__item {

                        p {
                            @apply mb-1 text-[#B2B2B2] font-semi;
                        }

                        h6 {
                            @apply text-color313131;
                        }
                    }
                }
            }
        }
    }

    &-transaction {

        &__item {

            &-content {

                .pipeline {

                    &-name {
                        @apply lg:w-[200px];

                        p {
                            @apply mb-2 text-color828282 text-sm;
                        }
                    }

                    &-date {

                        p {

                            + p {
                                @apply border-l border-l-color828282 pl-2 ml-2;
                            }
                        }
                    }

                    &-arrow {
                        @apply bg-no-repeat bg-contain bg-center lg:w-[140px] h-6;

                        &--left {
                            // background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEkAAAAECAYAAADCtRJuAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAkSURBVHgB7dABAQAACMIwtH8zQmkDJcAXYWV7hFMLL5ICJAUWgocDaHWXezIAAAAASUVORK5CYII=");
                            background-image: url('../images/Line-1.png');
                        }

                        &--right {
                            // background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAE0AAAAeCAYAAABpE5PpAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAE5SURBVHgB7dnNbcJAEIbh3SjyNZSQImyfKSEdhFRAC0kFwRWEVAAlwNW25Fa4+4/5JA7WyoIdc/zmlRD2SFweIa3G9o6spmlWXddt5XLtvS/SND06ZS+OrL7vf+XrWz7rcRwPdV1/OmV0aAK1Cu73Wjg6NKkIB1o4OrQsy06C9BXONXDekVaW5UYOgr9wLrONHA7/935Li4aWwlGjoSVw9GhIC2dotzRwhjYpFs7QgmLgDG2mR3C+qqrRWdEBjnGNeirZHHaGpkz+aRdDUzYMw48dBDNhcccCH86x6Od5vje0oEdguDa0STFgyNBuxYIhQ3M6MESPpgVD1GhLwBAt2lIwRIn2DBiiQxOwD7wkDuexYIjxZfF2ZhYNhujQZHc8T++1YOjVkZUkya5t23d5WvEmt4WAnZyyK/xu5jjTw7+pAAAAAElFTkSuQmCC");
                            background-image: url('../images/Arrow-1.png');
                        }
                    }
                }
            }
        }
    }
}