.page-tentang {

    .vision {

        &-text {

            h3 {
                @apply lg:text-8xl;
            }

            p {
                @apply lg:text-lg;
            }
        }

        &-row {

            .play {

                &-thumb {

                    img {
                        @apply w-full h-full object-cover object-center;
                    }
                }
            }
        }

        &-quality {

            &__title {

                h2 {
                    @apply mb-6;
                }

                p {
                    @apply lg:text-lg;
                }
            }

            &__content {

                > h4 {
                    @apply mb-6;
                }
            }

            &__certify {

                img {
                    @apply lg:h-12;
                }

                @include media-breakpoint-down(lg) {
                    @apply flex-wrap;

                    > div {
                        @apply w-full;
                    }
                }
            }
        }
    }
}